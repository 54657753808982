import React from "react";
import Meta from "components/Meta";
import PickList from "components/PickList";
import { requireAuth } from "util/auth";

function PickListPage(props) {
  return (
    <>
      <Meta title="Pick List" />
      <PickList
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Pick List"
      	subtitle=""
      />
    </>
  );
}

export default requireAuth(PickListPage);
