import React from "react";
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import clsx from 'clsx';
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faCircle as faSolidCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCircle as faOpenCircle } from '@fortawesome/pro-regular-svg-icons';
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Flex from 'components/Flex';
import BackBtn from 'components/BackBtn';
import Spinner from "components/Spinner";
import { getDefaultError } from 'util/error';
import { useParams, useHistory } from 'util/router';
import { 
	// Queries
	useGetPrunsQuery,
	// Mutations
	usePostPrunMutation,
} from 'api/client';
import { Badge, Button } from "react-bootstrap";
dayjs.extend(dayjsUtc);

function Prun(props) {
  const history = useHistory();
	const { prunId } = useParams();

  const {
		data: pruns = [],
		isLoading: prunsIsLoading,
    isFetching: prunsIsFetching,
    refetch: prunsRefetch,
    isError: prunsIsError,
    error: prunsError,
	} = useGetPrunsQuery({
		prunIds: [prunId],
    includeTransformedForEton: true,
	}, {
		pollingInterval: 5000,
	});

  const prun = pruns[0];
  // console.log(prun)

  return (
    <>
      {prunsIsError && <PrunError error={prunsError} refetch={prunsRefetch} />}
      <Section
			  bg={props.bg}
			  textColor={props.textColor}
			  size={props.size}
			  bgImage={props.bgImage}
			  bgImageOpacity={props.bgImageOpacity}
			>
				<Container fluid="2xl" className="mx-auto">
					<SectionHeader
					  title={!prunsIsLoading ? (prun?.prun ? `Prun ${prun.prun}` : 'Prun not found') : <Spinner className="text-muted"/>}
					  subtitle={!prunsIsLoading && prun?.coNum}
					  size={3}
					  spaced={true}
					  className="text-center"
					/>
          <Row>
						{/*Back btn*/}
						<Col xs={12}>
							<Flex
								align="center"
								style={{marginTop: '-1rem', marginBottom: '.5rem'}}
							>
								<BackBtn
									className="text-gray-700 text-gray-700-hvr-dark"
									onClick={() => history.goBack()}
								/>
							</Flex>
						</Col>
            {/*Summary*/}
						<Col md={12} lg={4}>
							<PrunSummaryCard
								prun={prun}
								isFetching={prunsIsFetching}
							/>
						</Col>
            <Col md={12} lg={4}>
              <SendToEtonCard 
								prun={prun} 
								prunsRefetch={prunsRefetch}
							/>
            </Col>
						<Col md={12} lg={4}>
							<PrunHistoryCard prun={prun} />
						</Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Prun;

const PrunError = ({error, refetch}) => (
	<Alert variant="danger">
		<Flex direction="column" align="center" className="w-100">
			<span>{getDefaultError(error)}</span>
			<span
				className="text-info-dark fw5 pointer"
				onClick={refetch}
			>
				Retry?
			</span>
		</Flex>
	</Alert>
);

const CheckIcon = ({ value }) => (
	<FontAwesomeIcon icon={value ? faCheck : faTimes} className={value ? 'text-success' : 'text-danger'} />
);

const PrunSummaryCard = ({ prun, isFetching }) => {
	return (
		<Card className="shadow mb-3">
			<Card.Header className="text-center">
        <Flex justify="between" align="center">
          <span>{prun?.prun || '-'}</span>
          {isFetching && (<Spinner className="text-muted"/>)}
        </Flex>
			</Card.Header>
			<Card.Body className="py-2">
				<Flex direction="column">
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Co Num / Line</span>
						<span className="text-right">{prun?.customerOrderItem?.coNum || '-'} / {prun?.customerOrderItem?.coLine || '-'}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Ticket</span>
						<span className="text-right">{prun?.ticket || '-'}</span>
					</Flex>
          <Flex justify="between" className="py-1">
						<span className="text-gray-700">Customer</span>
						<span className="text-right">{prun?.customer || '-'}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Item</span>
						<span className="text-right">{prun?.item?.item || '-'}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Collated</span>
						<span className="text-right">{prun?.sentToEton !== undefined ? <CheckIcon value={prun?.sentToEton} /> : '-'}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Created By</span>
						<span className="text-right">{prun?.createdBy || '-'}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Created Date</span>
						<span className="text-right">{prun?.createDate ? dayjs.utc(prun?.createDate).format('MM-DD-YY h:mm:ss a') : '-'}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Sent to Eton</span>
						<span
							className={clsx(
								'text-right',
								{ 'text-success': prun?.processed },
							)}
						>
							{
								prun?.processed ? dayjs.utc(prun?.processed).format('MM-DD-YY h:mm:ss a') 
									: prun?.sentToEton ? <Spinner animation="border" variant="muted" size="sm" /> 
										: <CheckIcon value={false} />
							}
						</span>
					</Flex>
          <Flex justify="between" className="py-1">
						<span className="text-gray-700">Complete</span>
						<span 
							className={clsx(
								'text-right',
								{ 'text-success': prun?.complete },
							)}
						>
							{
								prun?.complete ? dayjs.utc(prun?.complete).format('MM-DD-YY h:mm:ss a')
									: prun?.processed ? <Spinner animation="border" variant="muted" size="sm" />
										: <CheckIcon value={false} />
							}
						</span>
					</Flex>
				</Flex>
			</Card.Body>
		</Card>
	);
};

const SendToEtonCard = ({ prun, prunsRefetch }) => {
	const eton = prun?.eton;
  const [orderLine] = eton?.orderLines || [];

  return (
    <Card className="shadow mb-3">
			<Card.Header>
				<Flex justify="between" align="center">
        	<span>Send To Eton Structure</span>
					<SendToEtonButton 
						prun={prun}
						prunsRefetch={prunsRefetch}
					/>
				</Flex>
			</Card.Header>
			<Card.Body className="py-2">
				<Flex direction="column">
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Order Number</span>
						<span className="text-right">{eton?.orderNumber || '-'}</span>
					</Flex>
          <Flex justify="between" className="py-1">
						<span className="text-gray-700">Original Order Number</span>
						<span className="text-right">{eton?.originalOrderNumber || '-'}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Customer</span>
						<span className="text-right">{eton?.customer || '-'}</span>
					</Flex>
          {/* Order Line */}
          <div className="bg-gray-300 w-full my-3" style={{height: '1px'}} />
          <span className="text-gray-700 f-rem-0.85 fw-6">Order Line</span>
          <Flex justify="between" className="py-1">
						<span className="text-gray-700">Order Line Number</span>
						<span className="text-right">{orderLine?.orderLineNumber || '-'}</span>
					</Flex>
          <Flex justify="between" className="py-1">
						<span className="text-gray-700">Article Number</span>
						<span className="text-right">{orderLine?.articleNumber || '-'}</span>
					</Flex>
          <Flex justify="between" className="py-1">
						<span className="text-gray-700">Quantity</span>
						<span className="text-right">{orderLine?.quantity || '-'}</span>
					</Flex>
          <Flex justify="between" className="py-1">
						<span className="text-gray-700">Quantity Per Carrier</span>
						<span className="text-right">{orderLine?.quantityPerCarrier || '-'}</span>
					</Flex>
          {/* Criteria */}
          <div className="bg-gray-300 w-full my-3" style={{height: '1px'}} />
          <span className="text-gray-700 f-rem-0.85 fw-6">Criteria</span>
          <Flex justify="between" className="py-1">
						<span className="text-gray-700">Bottom Hem</span>
						<span className="text-right">{orderLine?.criteria?.["Bottom Hem"] || '-'}</span>
					</Flex>
          <Flex justify="between" className="py-1">
						<span className="text-gray-700">Side Hem</span>
						<span className="text-right">{orderLine?.criteria?.["Side Hem"] || '-'}</span>
					</Flex>
          <Flex justify="between" className="py-1">
						<span className="text-gray-700">Pinset</span>
						<span className="text-right">{orderLine?.criteria?.["Pinset"] || '-'}</span>
					</Flex>
          <Flex justify="between" className="py-1">
						<span className="text-gray-700">Salvage</span>
						<span className="text-right">{orderLine?.criteria?.["Salvage"] || '-'}</span>
					</Flex>
          <Flex justify="between" className="py-1">
						<span className="text-gray-700">Tab</span>
						<span className="text-right">{orderLine?.criteria?.["Tab"] || '-'}</span>
					</Flex>
          <Flex justify="between" className="py-1">
						<span className="text-gray-700">Detail</span>
						<span className="text-right">{orderLine?.criteria?.["Detail"] || '-'}</span>
					</Flex>
				</Flex>
			</Card.Body>
		</Card>
  );
};

const SendToEtonButton = ({ prun, prunsRefetch }) => {
	const [showPostPrunSuccess, setShowPostPrunSuccess] = React.useState(false);
	const [showPostPrunError, setShowPostPrunError] = React.useState(null);
	const [postPrun, postPrunResult] = usePostPrunMutation();

	React.useEffect(() => {
		if (postPrunResult.isSuccess || postPrunResult.isError) {
			postPrunResult.reset();
			prunsRefetch();
		}

		if (postPrunResult.isError) {
			setShowPostPrunError(postPrunResult.error);
			console.error('useCancelOrdersMutation error', postPrunResult.error);
		}
		if (postPrunResult.isSuccess) setShowPostPrunSuccess(true);
	}, [postPrunResult, prunsRefetch]);

	return (
		<>
			<Button 
				variant="primary" 
				size="sm" 
				disabled={!prun || !prun?.prun || postPrunResult.isLoading || prun?.complete}
				onClick={() => postPrun({prunId: prun?.prun})}
			>
				{
					postPrunResult.isLoading ? <Spinner animation="border" variant="light" size="sm" /> 
						: prun?.complete ? 'Job Complete' 
							: prun?.processed ? 'Resend to Eton'
								: 'Send To Eton'
				}
			</Button>

			<ShowProcessPrunResult
				showPostPrunSuccess={showPostPrunSuccess}
				setShowPostPrunSuccess={setShowPostPrunSuccess}
				showPostPrunError={showPostPrunError}
				setShowPostPrunError={setShowPostPrunError}
			/>
		</>
	);
};

const PrunHistoryCard = ({ prun }) => {
	return (
		<Card className="shadow mb-3">
			<Card.Header>
				<Flex justify="between" align="center">
					<span>Prun History</span>
					<Badge 
						variant={clsx({ 
							'success': prun?.complete,
							'info text-white': prun?.processed && !prun?.complete,
							'warning': prun?.sentToEton && !prun?.processed && !prun?.complete,
							'muted': !prun?.sentToEton,
						})}
					>
						{
							prun?.complete ? 'COMPLETE'
								:	prun?.processed ? 'SENT TO ETON'
									: prun?.sentToEton ? 'COLLATED'
										: 'CREATED'
						}
					</Badge>
				</Flex>
			</Card.Header>
			<Card.Body className="py-2">
				<TimelineItem
					active={prun?.createDate}
					label="Created"
					value={prun?.createDate ? dayjs.utc(prun.createDate).format('MM-DD-YY h:mm:ss a') : '-'}
					isFirst={true}
				/>
				<TimelineItem
					active={prun?.sentToEton}
					label="Collated"
					value={<CheckIcon value={prun?.sentToEton} />}
				/>
				<TimelineItem
					active={prun?.processed}
					label="Sent to Eton"
					value={prun?.processed ? dayjs.utc(prun.processed).format('MM-DD-YY h:mm:ss a') : <CheckIcon value={false} />}
				/>
				<TimelineItem
					active={prun?.complete}
					label="Complete"
					value={prun?.complete ? dayjs.utc(prun.complete).format('MM-DD-YY h:mm:ss a') : <CheckIcon value={false} />}
					isLast={true}
				/>
			</Card.Body>
		</Card>
	);
};

const TimelineItem = ({ active, label, value, isFirst, isLast }) => (
	<Flex 
		align="start" 
		className={clsx({ 
			'mt-2': isFirst,
			'mb-2': isLast,
			'mb-3': !isLast,
		})} 
		style={{height: '28px'}}
	>
		<Flex 
			direction="column" 
			align="center" 
			className="pr-2.5" 
			style={{paddingTop: '6px'}}
		>
			<FontAwesomeIcon 
				icon={active ? faSolidCircle : faOpenCircle} 
				className={clsx(
					'f-rem-0.75',
					{ 'text-success': active },
					{ 'text-muted': !active }
				)} 
			/>
			{!isLast && (
				<div 
					className={clsx(
						{ 'bg-success': active },
						{ 'bg-muted': !active }
					)} 
					style={{
						width: '2px',
						height: '32px',
					}} 
				/>
			)}
		</Flex>
		<Flex direction="column">
			<span className="f-rem-0.85 fw-6 text-gray-700" style={{lineHeight: '0.8'}}>{label}</span>
			<span>{value}</span>
		</Flex>
	</Flex>
);

export const ShowProcessPrunResult = ({
	showPostPrunSuccess, 
	setShowPostPrunSuccess, 
	showPostPrunError, 
	setShowPostPrunError
}) => (
	<>
		{/*Cancel order success*/}
		<Toast
			onClose={() => setShowPostPrunSuccess(false)}
			show={showPostPrunSuccess}
			delay={3000}
			autohide
			style={{
				position: 'fixed',
				top: 16,
				right: 16,
			}}
		>
			<Toast.Header>
				<strong className="mr-auto text-success">Success</strong>
			</Toast.Header>
			<Toast.Body>
				Prun Sent to Eton successfully
			</Toast.Body>
		</Toast>
		{/*Cancel order error*/}
		<Toast
			onClose={() => setShowPostPrunError(false)}
			show={showPostPrunError}
			delay={3000}
			autohide
			style={{
				position: 'fixed',
				top: 16,
				right: 16,
			}}
		>
			<Toast.Header>
				<strong className="mr-auto text-danger">Failed</strong>
			</Toast.Header>
			<Toast.Body>{getDefaultError(showPostPrunError)}</Toast.Body>
		</Toast>
	</>
);