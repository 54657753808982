import { configureStore } from '@reduxjs/toolkit';
import {
	jobsApiSlice,
	pickListsApiSlice,
	prunsApiSlice,
	usersApiSlice,
} from '../api/client';

export default configureStore({
	reducer: {
		[jobsApiSlice.reducerPath]: jobsApiSlice.reducer,
		[pickListsApiSlice.reducerPath]: pickListsApiSlice.reducer,
		[prunsApiSlice.reducerPath]: prunsApiSlice.reducer,
		[usersApiSlice.reducerPath]: usersApiSlice.reducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(
			jobsApiSlice.middleware
		).concat(
			pickListsApiSlice.middleware
		).concat(
			prunsApiSlice.middleware
		).concat(
			usersApiSlice.middleware
		)
});
