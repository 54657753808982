import React from "react";
import Meta from "components/Meta";
import JobsSection from "components/JobsSection";
import { requireAuth } from "util/auth";

function JobsPage(props) {
  return (
    <>
      <Meta title="Jobs" />
      <JobsSection
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Jobs"
      	subtitle="Search below or scan a job barcode"
      />
    </>
  );
}

export default requireAuth(JobsPage);
