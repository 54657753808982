import React, { useMemo } from "react";
import dayjs from "dayjs";
import dayjsUtc from 'dayjs/plugin/utc';
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import StripeTable from "components/StripeTable";
dayjs.extend(dayjsUtc);

const DEFAULT_SORT_COL = 'pickDate';
const DEFAULT_SORT_DIRECTION = 'DESC';

const HEADERS = [
	{
		key: 'prun',
		name: 'Prun',
	}, {
		key: 'ticket',
		name: 'Ticket',
	}, {
		key: 'customer',
		name: 'Customer',
	}, {
		key: 'item.item',
		name: 'Item',
	}, {
		key: 'sentToEton',
		name: 'Collated',
	}, {
		key: 'createdBy',
		name: 'Created By',
	}, {
		key: 'createDate',
		name: 'Created',
	}, {
		key: 'processed',
		name: 'Sent to Eton',
	}, {
		key: 'complete',
		name: 'Complete',
	},
];

function PrunsTable(props) {
	const {
		data: _data = [],
		...rest
	} = props;

	const data = useMemo(() => {
		return _data.map(prun => ({
			id: prun.prun,
			orig: prun,
			values: [
				// Prun
				{ value: prun?.prun || '-', className: 'text-nowrap' },
				// Ticket
				{ value: prun?.ticket || '-', className: 'text-nowrap' },
				// Customer
				{ value: prun?.customer || '-', className: 'text-nowrap' },
				// Item
				{ value: prun?.item?.item || '-', className: 'text-nowrap' },
				// Sent to Eton
				{ value: prun?.sentToEton !== undefined ? <CheckIcon value={prun?.sentToEton} /> : '-', className: 'text-nowrap' },
				// Created By
				{ value: prun?.createdBy || '-', className: 'text-nowrap' },
				// Created
				{ value: prun?.createDate ? dayjs.utc(prun?.createDate).format('MM-DD-YY h:mm:ss a') : '-', className: 'text-nowrap' },
				// Processed
				{ 
					value: prun?.processed ? dayjs.utc(prun?.processed).format('MM-DD-YY h:mm:ss a') 
						: prun.sentToEton ? <Spinner animation="border" variant="muted" size="sm" /> 
							: <CheckIcon value={false} />, 
					className: `text-nowrap${prun.processed ? ' text-success' : ''}`
				},
				// Complete
				{ 
					value: prun?.complete ? dayjs.utc(prun?.complete).format('MM-DD-YY h:mm:ss a') 
						: prun.processed ? <Spinner animation="border" variant="muted" size="sm" />
							: <CheckIcon value={false} />,
					className: `text-nowrap${prun.complete ? ' text-success' : ''}`
				},
			],
		}))
	}, [_data]);

	return (
		<StripeTable
			headers={HEADERS}
			defaultSortCol={DEFAULT_SORT_COL}
			defaultSortDir={DEFAULT_SORT_DIRECTION}
			data={data}
			noneFoundText="No pruns found"
			{...rest}
		/>
	);
};

export default PrunsTable;

const CheckIcon = ({ value }) => (
	<FontAwesomeIcon icon={value ? faCheck : faTimes} className={value ? 'text-success' : 'text-danger'} />
);