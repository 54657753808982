import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Spinner from "components/Spinner";
import Flex from "components/Flex";
import { useRouter } from 'util/router';

function Pagination({ isLoading }) {
  const router = useRouter();
  const [page, setPage] = React.useState(Number(router.query.page) || 1);

  const handleNavigation = (value = 0) => {
    const newPage = Math.max(1, page + value);

    const queryParams = new URLSearchParams({
      ...router.query,
      page: newPage,
    });
    router.push({ pathname: router?.location?.pathname, search: queryParams.toString() });

    setPage(newPage);
  };

  return (
    <Flex>
      <ButtonGroup size="sm">
        <Button 
          variant="outline-secondary"
          size="sm"
          disabled={page <= 1 || isLoading}
          onClick={() => handleNavigation(-1)}
          className="py-1"
        >
          Previous
        </Button>
        <Button variant="outline-secondary">
          {isLoading ? <Spinner /> : page}
        </Button>
        <Button 
          variant="outline-secondary"
          disabled={isLoading}
          onClick={() => handleNavigation(1)}
        >
          Next
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export default Pagination;