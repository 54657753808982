import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Flex from "components/Flex";
import Popup from "components/Popup";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import SearchInput from "components/SearchInput";
import Spinner from "components/Spinner";
import Toggle from "components/Toggle";
import JobsTable from 'components/JobsTable';
import useOnScan from 'util/onScan';
import { useGetJobsQuery, useGetJobQuery } from 'api/client';
import { useRouter } from 'util/router';

const STATUS_TOGGLES = ['all', 'released'];
const DEFAULT_STATUS_TOGGLE = STATUS_TOGGLES[1];

function scanValidation(scanCodeRaw) {
	if (!scanCodeRaw) return false;
	const startsWithJ = /^J/.test(scanCodeRaw);
	const validLen = scanCodeRaw.length === 15;
	const [prefix, suffix] = scanCodeRaw.split('-');
	const prefixIsNum = !isNaN(Number(prefix.replace('J', '')));
	const suffixIsNum = !isNaN(Number(suffix));

	return startsWithJ && validLen && !!prefix && !!suffix && prefixIsNum && suffixIsNum;
}

function JobsSection(props) {
	const router = useRouter();
	const popupRef = useRef();
	const [statusToggle, setStatusToggle] = useState(router?.query?.status || DEFAULT_STATUS_TOGGLE);
	const [search, setSearch] = useState('');
	const [sort, setSort] = useState('jobDate');
	const [sortDir, setSortDir] = useState('DESC');
	const [selectedJobId, setSelectedJobId] = useState(router.params.jobId || null);

	const {
		data: jobs = [],
		isLoading: jobsIsLoading,
	} = useGetJobsQuery({status: statusToggle, search, sort, sortDir});
	// console.log({jobs, jobsIsLoading});

	useEffect(() => {
		const queryParams = new URLSearchParams({ status: statusToggle });
		router.history.replace({ pathname: router?.location?.pathname, search: queryParams.toString() });
	}, [statusToggle, router?.history, router?.location?.pathname]);

	const handleNavigateToJob = useCallback(({ jobId, jobSuffix }) => {
		router.history.push(`/jobs/${jobId}/${jobSuffix}`);
	}, [router.history]);

	const onScanCallback = useCallback((jobIdWithSuffix) => {
		const [jobId, jobSuffix] = jobIdWithSuffix.split('-');
		handleNavigateToJob({
			jobId,
			jobSuffix: Number(jobSuffix).toString()
		});
	}, [handleNavigateToJob]);

	// eslint-disable-next-line
	const [scanCode, scanError] = useOnScan({
		options: { minLength: 15 },
		validation: [scanValidation, 'is not a valid Job ID'],
		onScanCallback,
	});

	useEffect(() => {
		if (selectedJobId) {
			popupRef.current.openPopup();
		}
	}, [selectedJobId]);

	return (
		<>
			{scanError && (
				<Alert variant="danger">
					<Flex direction="column" align="center" className="w-100">
						<span>{scanError.message}</span>
					</Flex>
				</Alert>
			)}
			<Section
			  bg={props.bg}
			  textColor={props.textColor}
			  size={props.size}
			  bgImage={props.bgImage}
			  bgImageOpacity={props.bgImageOpacity}
			>
			  <Container>
			  	<SectionHeader
			  	  title={props.title}
			  	  subtitle={props.subtitle}
			  	  size={3}
			  	  className="text-center"
			  	/>
			  	<Toggle
			  		ops={STATUS_TOGGLES}
			  		active={statusToggle}
			  		setActive={setStatusToggle}
			  		className="mb-2 w-fit"
			  	/>
			  	<Card className="shadow">
			  		<Card.Header>
			  			<SearchInput
			  				placeholder="Search by Job ID"
			  				setSearch={setSearch}
			  				debounceTime={500}
			  			/>
			  		</Card.Header>
			  		<Card.Body className="p-0">
		  				<div>
		  					<JobsTable
		  						jobs={jobs}
		  						isLoading={jobsIsLoading}
		  						hasLocalSearch={false}
		  						hasLocalSort={false}
		  						sort={sort}
		  						setSort={setSort}
		  						sortDir={sortDir}
		  						setSortDir={setSortDir}
		  						handleRowClick={({ jobId }) => setSelectedJobId(jobId)}
		  						useMobileTable={true}
		  					/>
						  </div>
			  		</Card.Body>
			  	</Card>
			  </Container>
			</Section>
			<Popup
				ref={popupRef}
				title={`Job ${selectedJobId} - ${jobs.find(j => j.job === selectedJobId)?.moJobDescription || '...'}`}
				size="xl"
				afterClose={() => {
					setSelectedJobId(null);
				}}
			>
				{selectedJobId && (
					<SubJobsTable
						selectedJobId={selectedJobId}
						handleNavigateToJob={handleNavigateToJob}
					/>
				)}
			</Popup>
		</>
	);
};

const SubJobsTable = ({selectedJobId, handleNavigateToJob}) => {
	const {
		data: job = {},
		isLoading: jobIsLoading,
	} = useGetJobQuery({
		jobId: selectedJobId,
		includeRelatedJobs: true,
	});

	const relatedJobs = useMemo(() => {
		return [
			...!jobIsLoading ? [
				job,
			] : [],
			...job?.relatedJobs || []
		]
	}, [job, jobIsLoading]);

	return jobIsLoading ? (
		<Spinner className="py-2 text-muted w-100"/>
	) : relatedJobs.length > 0 ? (
		<div>
			<JobsTable
				jobs={relatedJobs}
				isLoading={jobIsLoading}
				showSuffix={true}
				handleRowClick={handleNavigateToJob}
				hasLocalSearch={false}
				hasLocalSort={true}
				sort="suffix"
				sortDir="ASC"
				useMobileTable={true}
			/>
		</div>
	) : (
		<div className="text-center py-2">
			<span>Job not found</span>
		</div>
	);
};

export default JobsSection;
