import React, { useState, useEffect, useCallback } from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Flex from "components/Flex";
import Section from "components/Section";
import PickListsTable from 'components/PickListsTable';
import SectionHeader from "components/SectionHeader";
import SearchInput from "components/SearchInput";
import Toggle from "components/Toggle";
import useOnScan from 'util/onScan';
import { useGetPickListsQuery } from 'api/client';
import { useRouter } from 'util/router';

// const STATUS_TOGGLES = ['all', 'not packed'];
// const DEFAULT_STATUS_TOGGLE = STATUS_TOGGLES[1];
const STATUS_TOGGLES = ['all'];
const DEFAULT_STATUS_TOGGLE = STATUS_TOGGLES[0];

function PickListsSection(props) {
	const router = useRouter();
	const [statusToggle, setStatusToggle] = useState(router?.query?.status || DEFAULT_STATUS_TOGGLE);
	const [search, setSearch] = useState('');
	const [sort, setSort] = useState('pickDate');
	const [sortDir, setSortDir] = useState('DESC');

	const {
		data: pickLists = [],
		isLoading: pickListsIsLoading,
	} = useGetPickListsQuery({status: statusToggle, search, sort, sortDir});
	// console.log({pickLists, pickListsIsLoading});

	useEffect(() => {
		const queryParams = new URLSearchParams({ status: statusToggle });
		router.history.replace({ pathname: router?.location?.pathname, search: queryParams.toString() });
	}, [statusToggle, router?.history, router?.location?.pathname]);

	const handleNavigateToPickList = useCallback((id) => {
		router.history.push(`/pickLists/${id}`);
	}, [router.history]);

	const onScanCallback = useCallback((id) => {
		handleNavigateToPickList(Number(id).toString());
	}, [handleNavigateToPickList]);

	// eslint-disable-next-line
	const [scanCode, scanError] = useOnScan({
		validation: [
			(scanCode) => !!scanCode && !isNaN(Number(scanCode)),
			'is not a valid PickList ID'
		],
		onScanCallback,
	});

	return (
		<>
			{scanError && (
				<Alert variant="danger">
					<Flex direction="column" align="center" className="w-100">
						<span>{scanError.message}</span>
					</Flex>
				</Alert>
			)}
			<Section
			  bg={props.bg}
			  textColor={props.textColor}
			  size={props.size}
			  bgImage={props.bgImage}
			  bgImageOpacity={props.bgImageOpacity}
			>
			  <Container>
			  	<SectionHeader
			  	  title={props.title}
			  	  subtitle={props.subtitle}
			  	  size={3}
			  	  className="text-center"
			  	/>
			  	<Toggle
			  		ops={STATUS_TOGGLES}
			  		active={statusToggle}
			  		setActive={setStatusToggle}
			  		className="mb-2 w-fit"
			  	/>
  		  	<Card className="shadow">
  		  		<Card.Header>
  		  			<SearchInput
  		  				placeholder="Search by Pick List ID"
  		  				setSearch={setSearch}
  		  				debounceTime={500}
  		  			/>
  		  		</Card.Header>
  		  		<Card.Body className="p-0">
  	  				<div>
  	  					<PickListsTable
  	  						pickLists={pickLists}
  	  						isLoading={pickListsIsLoading}
  	  						hasLocalSort={false}
  	  						sort={sort}
  	  						setSort={setSort}
  	  						sortDir={sortDir}
  	  						setSortDir={setSortDir}
  	  						handleRowClick={handleNavigateToPickList}
  	  						useMobileTable={true}
  	  					/>
  					  </div>
  		  		</Card.Body>
  		  	</Card>
			  </Container>
			</Section>
		</>
	);
};

export default PickListsSection;
