import React from "react";
import Meta from "components/Meta";
import PickListsSection from "components/PickListsSection";
import { requireAuth } from "util/auth";

function PickListsPage(props) {
  return (
    <>
      <Meta title="Pick N Pack Lists" />
      <PickListsSection
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Pick N Pack Lists"
      	subtitle="Search below or scan a pick list barcode"
      />
    </>
  );
}

export default requireAuth(PickListsPage);
