import React from "react";
import "./../styles/global.scss";
import { Switch, Route, Router, Redirect } from "./../util/router";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import NavbarCustom from "./../components/NavbarCustom";
import Footer from "./../components/Footer";
import AuthPage from "./auth";
import JobsPage from "./jobs";
import JobPage from "./job";
import PickListsPage from "./pickLists";
import PickListPage from "./pickList";
import PrunsPage from "./pruns";
import PrunPage from "./prun";
import UsersPage from "./users";
// import SettingsPage from "./settings";
import NotFoundPage from "./404";

const logo = "/img/logo-navbar.svg";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          <>
            <NavbarCustom
              bg="white"
              variant="light"
              expand="md"
              logo={logo}
            />

            <Switch>
              {/*<Route exact path="/settings">
                <Redirect to="/settings/general" />
              </Route>
              <Route exact path="/settings/:section" component={SettingsPage} />*/}

              <Route exact path="/">
                <Redirect to="/auth/signin" />
              </Route>
              <Route exact path="/auth/:type" component={AuthPage} />
              <Route exact path="/jobs" component={JobsPage} />
              <Route exact path="/jobs/:jobId" component={JobsPage} />
              <Route exact path="/jobs/:jobId/:jobSuffix" component={JobPage} />
              <Route exact path="/pickLists" component={PickListsPage} />
              <Route exact path="/pickLists/:pickListId" component={PickListPage} />
              <Route exact path="/pruns" component={PrunsPage} />
              <Route exact path="/pruns/:prunId" component={PrunPage} />
              <Route exact path="/users" component={UsersPage} />
              <Route component={NotFoundPage} />
            </Switch>

            <Footer
              bg="light"
              textColor="dark"
              size="sm"
              bgImage=""
              bgImageOpacity={1}
              copyright={`© ${new Date().getFullYear()} County Draperies`}
              logo={logo}
            />
          </>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
