import queryConstructor from '../../util/queryConstructor';
import { keepUnusedDataFor } from './constants';

function getPickLists({status, search, sort, sortDir}) {
	const queryParams = queryConstructor({status, search, sort, sortDir});
	return `fetchPickLists?${queryParams}`;
};

function getPickList({pickListId}) {
	return `fetchPickList/${pickListId}`;
}

// Mutations
function recordPickListQtyPicked({pickListId, sequence, item, refNum, refLineSuf, qty} = {}) {
	const queryParams = queryConstructor({pickListId, sequence, item, refNum, refLineSuf, qty});
	return `recordQtyPicked?${queryParams}`;
}

const handlers = (builder) => ({
	getPickLists: builder.query({
		query: getPickLists,
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getPickList: builder.query({
		query: getPickList,
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	// Mutations
	recordPickListQtyPicked: builder.mutation({
		query: recordPickListQtyPicked,
	}),
});

export default handlers;
