import React, { useMemo } from "react";
import dayjs from "dayjs";
import StripeTable from 'components/StripeTable';

const DEFAULT_SORT_COL = 'jobDate';
const DEFAULT_SORT_DIRECTION = 'DESC';

const HEADERS = [
	{
		key: 'job',
		name: 'ID',
	}, {
		key: 'moJobDescription',
		name: 'Job',
	}, {
		key: 'item',
		name: 'Item',
	}, {
		key: 'description',
		name: 'Desc',
	}, {
		key: 'jobDate',
		name: 'Job Date',
		hasSort: true,
	}, {
		key: 'endDate',
		name: 'End Date',
		hasSort: true,
	},
];

function JobsTable(props) {
	const {
		jobs = [],
		showSuffix = false,
		hasLocalSearch = false,
		...rest
	} = props;

	const data = useMemo(() => {
		return jobs.map(job => ({
			id: `${job.job}-${job.suffix}`,
			callbackValue: { jobId: job.job, jobSuffix: job.suffix },
			orig: job,
			values: [
				{ value: `${job?.job}${showSuffix ? `-${String(job?.suffix).padStart(4, '0')}` : ''}`.trim(), className: 'text-nowrap' },
				{ value: job?.moJobDescription || '-' },
				{ value: job?.item || '-', className: 'text-nowrap' },
				{ value: job?.description || '-' },
				{ value: dayjs(job?.jobDate).format('MM-DD-YYYY'), className: 'text-nowrap' },
				{ value: dayjs(job?.endDate).format('MM-DD-YYYY'), className: 'text-nowrap' },
			],
		}))
	}, [jobs, showSuffix]);

	return (
		<StripeTable
			headers={HEADERS}
			defaultSortCol={DEFAULT_SORT_COL}
			defaultSortDir={DEFAULT_SORT_DIRECTION}
			hasLocalSearch={hasLocalSearch}
			data={data}
			noneFoundText="No jobs found"
			{...rest}
		/>
	);
};

export default JobsTable;
