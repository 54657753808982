import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import usersHandlers from 'api/users';
import supabase from 'util/supabase';
import jobsHandlers from './jobs';
import pickListsHandlers from './pickLists';
import prunsHandlers from './pruns';
import { apiUri } from './constants';

// Jobs
export const jobsApiSlice = createApi({
	reducerPath: 'jobs',
	baseQuery: fetchBaseQuery({
		baseUrl: `${apiUri}/jobs`,
		prepareHeaders: (headers, { getState }) => {
			headers.set('Cache-Control', 'no-cache');
			headers.set('Pragma', 'no-cache');
			headers.set('Expires', '0');

			const { access_token } = supabase.auth.session() || {};
			if (access_token) {
				headers.set('authorization', access_token);
			}

			return headers;
		},
	}),
	endpoints: builder => jobsHandlers(builder),
});

export const {
	useGetJobsQuery, useGetJobQuery,
	// Mutations
	useRecordJobMatlQtyPickedMutation,
} = jobsApiSlice;

// Pick Lists
export const pickListsApiSlice = createApi({
	reducerPath: 'pickLists',
	baseQuery: fetchBaseQuery({
		baseUrl: `${apiUri}/pickLists`,
		prepareHeaders: (headers, { getState }) => {
			headers.set('Cache-Control', 'no-cache');
			headers.set('Pragma', 'no-cache');
			headers.set('Expires', '0');

			const { access_token } = supabase.auth.session() || {};
			if (access_token) {
				headers.set('authorization', access_token);
			}

			return headers;
		},
	}),
	endpoints: builder => pickListsHandlers(builder),
});

export const {
	useGetPickListsQuery, useGetPickListQuery,
	// Mutations
	useRecordPickListQtyPickedMutation,
} = pickListsApiSlice;

// Orders
export const prunsApiSlice = createApi({
	reducerPath: 'pruns',
	baseQuery: fetchBaseQuery({
		baseUrl: `${apiUri}/pruns`,
		prepareHeaders: (headers, { getState }) => {
			headers.set('Cache-Control', 'no-cache');
			headers.set('Pragma', 'no-cache');
			headers.set('Expires', '0');

			const { access_token } = supabase.auth.session() || {};
			if (access_token) {
				headers.set('authorization', access_token);
			}

			return headers;
		},
	}),
	endpoints: builder => prunsHandlers(builder),
});

export const {
	useGetPrunsQuery,
	// Mutations
	usePostPrunMutation,
} = prunsApiSlice;

// Users
export const usersApiSlice = createApi({
	reducerPath: 'users',
	baseQuery: apiUri,
	endpoints: builder => usersHandlers(builder),
});

export const {
	useGetUsersQuery
} = usersApiSlice;
