import React, { useMemo } from 'react';
import classnames from 'classnames';
import sortBy from 'lodash/sortBy';
import Flex from "components/Flex";
import StripeTable from 'components/StripeTable';
import useIsMobile from "hooks/useIsMobile";
import { commaFormat } from 'util/numbers';

const HEADERS = [
	{
		key: 'operNum',
		name: 'Oper',
		hasSort: true,
	}, {
		key: 'sequence',
		name: 'Seq',
		hasSort: true,
	}, {
		key: 'item',
		name: 'Item',
	}, {
		key: 'description',
		name: 'Desc',
	}, {
		key: 'unitOfMeasure',
		name: 'U/M',
	}, {
		key: 'matlQty_qtyIssued',
		name: 'Req/Issued',
	}, {
		key: 'qtyToPick',
		name: 'To Pick',
	}, {
		key: 'availAtLoc',
		name: 'Qty avail loc',
	}, {
		key: 'location',
		name: 'Location',
	},
];

function MaterialsTable(props) {
	const [isMobile] = useIsMobile();
	const {
		materials = [],
		isLoading,
		hasLocations = true,
		handleRowClick,
		className: tableClassName = 'table-stripe',
		useMobileTable = false,
		...rest
	} = props;

	const headers = useMemo(() => {
		return hasLocations ? HEADERS : HEADERS.slice(0, -2);
	}, [hasLocations]);

	const data = useMemo(() => {
		return materials.flatMap(material => {
			const locations = sortBy(
				material?.locations || [{location: '-'}],
				'rank'
			);

			return locations.map(location => ({
				key: `${material?.item}-${location?.location || '-'}`,
				id: material?.item,
				orig: material,
				values: useMobileTable && isMobile ? [
					{
						raw: true,
						value: (
							<Flex key="ItemDesc" direction="column" className="mb-2">
								<span className="f-rem-1.1 fw-5">{material?.item || '-'}</span>
								<span className="f-rem-0.9 text-gray-800">{material?.description || '-'}</span>
							</Flex>
						)
					},
					{
						raw: true,
						value: (
							<MobileValue
								key="OperSeq"
								leftTitle="Oper"
								leftValue={commaFormat(material?.operNum, undefined, '-')}
								rightTitle="Seq"
								rightValue={commaFormat(material?.sequence, undefined, '-')}
							/>
						)
					},
					{
						raw: true,
						value: (
							<MobileValue
								key="TotalReq-TotalToPick"
								leftTitle="Total Req"
								leftValue={commaFormat(material?.totalReq, undefined, '-')}
								rightTitle="Issued"
								rightValue={commaFormat(material?.qtyIssued, undefined, '-')}
							/>
						)
					},
					{
						raw: true,
						value: (
							<MobileValue
								key="ToPick-UnitOfMeasure"
								leftTitle="To Pick"
								leftValue={commaFormat(location?.qtyToPick, undefined, '-')}
								rightTitle="U/M"
								rightValue={material?.unitOfMeasure || '-'}
							/>
						)
					},
					...hasLocations ? [
						{
							raw: true,
							value: (
								<MobileValue
									key="Location-QtyOnHand"
									leftTitle="Location"
									leftValue={location?.location || '-'}
									rightTitle="On Hand"
									rightValue={commaFormat(location?.qtyOnHand, undefined, '-')}
								/>
							)
						}
					] : [],
				] : [
					// operNum
					{ value: commaFormat(material?.operNum, undefined, '-') },
					// sequence
					{ value: commaFormat(material?.sequence, undefined, '-') },
					// item
					{
						value: material?.item || '-',
						className: 'text-nowrap',
					},
					// description
					{
						value: material?.description || '-',
						className: 'text-nowrap text-truncate',
					},
					// unitOfMeasure
					{ value: material?.unitOfMeasure || '-' },
					// totalReq, qtyIssued
					{ value: `${commaFormat(material?.totalReq, undefined, '-')} / ${commaFormat(material?.qtyIssued, undefined, '-')}` },
					// qtyToPick
					{ value: commaFormat(location?.qtyToPick, undefined, '-') },
					...hasLocations ? [
						// qtyOnHand
						{ value: commaFormat(location?.qtyOnHand, undefined, '-') },
						// location
						{
							value: location?.location || '-',
							className: 'text-nowrap',
						}
					] : [],
				],
				callbackValue: { material, location }
			}));
		});
	}, [materials, hasLocations, useMobileTable, isMobile]);

	return (
		<StripeTable
			headers={headers}
			data={data}
			noneFoundText="No materials found"
			useMobileTable={useMobileTable}
			className={tableClassName}
			handleRowClick={handleRowClick}
			{...rest}
		/>
	);
};

const MobileValue = ({leftTitle, leftValue, leftClassName = '', rightTitle, rightValue, rightClassName = ''}) => (
	<Flex justify="between">
		<Flex direction="column">
			<span
				className="f-rem-0.8 fw-5 text-gray-600 text-uppercase mb-0.5"
			>
				{leftTitle}
			</span>
			<span className={classnames('f-rem-1.1', {
				[leftClassName || '']: true,
			})}>
				{leftValue}
			</span>
		</Flex>
		<Flex direction="column" className="text-right">
			<span
				className="f-rem-0.8 fw-5 text-gray-600 text-uppercase mb-0.5"
			>
				{rightTitle}
			</span>
			<span className={classnames('f-rem-1.1', {
				[rightClassName || '']: true,
			})}>
				{rightValue}
			</span>
		</Flex>
	</Flex>
);

export default MaterialsTable;
