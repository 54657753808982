import React, { useState, useEffect, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Flex from "components/Flex";
import Section from "components/Section";
import Pagination from "components/Pagination";
import PrunsTable from 'components/PrunsTable';
import SectionHeader from "components/SectionHeader";
import SearchInput from "components/SearchInput";
import Toggle from "components/Toggle";
import { useGetPrunsQuery } from 'api/client';
import { useRouter } from 'util/router';

const STATUS_TOGGLES = {
	'All': 'all',
	'Unsent': 'unsent',
	'Collated': 'sentToEton',
	'Sent to Eton': 'processed',
	'Complete': 'complete',
};
const DEFAULT_STATUS_TOGGLE = Object.keys(STATUS_TOGGLES)[0];

function OrdersSection(props) {
	const router = useRouter();
	const [statusToggle, setStatusToggle] = useState(router?.query?.status || DEFAULT_STATUS_TOGGLE);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState('');
	const [sort, setSort] = useState('createDate');
	const [sortDir, setSortDir] = useState('DESC');

	const {
		data: pruns = [],
		isLoading: prunsIsLoading,
		isFetching: prunsIsFetching,
	} = useGetPrunsQuery({
		status: STATUS_TOGGLES[statusToggle], 
		search,
		page: Number(router.query.page) || 1,
		sort, 
		sortDir,
	}, {
		pollingInterval: 10000,
	});

	useEffect(() => {
		const queryParams = new URLSearchParams({ status: statusToggle, page });
		router.history.replace({ pathname: router?.location?.pathname, search: queryParams.toString() });
	}, [
		statusToggle,
		page,
		router?.history, 
		router?.location?.pathname
	]);

	const handleNavigateToOrder = useCallback((id) => {
		router.history.push(`/pruns/${id}`);
	}, [router.history]);

	return (
		<>
			<Section
			  bg={props.bg}
			  textColor={props.textColor}
			  size={props.size}
			  bgImage={props.bgImage}
			  bgImageOpacity={props.bgImageOpacity}
			>
			  <Container fluid="2xl" className="mx-auto">
			  	<SectionHeader
			  	  title={props.title}
			  	  subtitle={props.subtitle}
			  	  size={3}
			  	  className="text-center"
			  	/>
					<Flex justify="between" className="mb-2">
						<Toggle
							ops={Object.keys(STATUS_TOGGLES)}
							active={statusToggle}
							setActive={(op) => {
								setStatusToggle(op);
								setPage(1);
							}}
							className="w-fit"
						/>
						<Pagination isLoading={prunsIsFetching} />
					</Flex>
  		  	<Card className="shadow">
  		  		<Card.Header>
  		  			<SearchInput
  		  				placeholder="Search by Prun Number"
  		  				setSearch={setSearch}
  		  				debounceTime={500}
  		  			/>
  		  		</Card.Header>
  		  		<Card.Body className="p-0">
  	  				<div>
  	  					<PrunsTable
  	  						data={pruns}
  	  						isLoading={prunsIsLoading}
  	  						hasLocalSort={false}
  	  						sort={sort}
  	  						setSort={setSort}
  	  						sortDir={sortDir}
  	  						setSortDir={setSortDir}
  	  						handleRowClick={handleNavigateToOrder}
  	  						useMobileTable={true}
  	  					/>
  					  </div>
  		  		</Card.Body>
  		  	</Card>
			  </Container>
			</Section>
		</>
	);
};

export default OrdersSection;
