import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import classnames from 'classnames';
import dayjs from 'dayjs';
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faBarcodeRead } from '@fortawesome/pro-duotone-svg-icons';
import SearchInput from "components/SearchInput";
import FormField from "components/FormField";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Popup from "components/Popup";
import Flex from 'components/Flex';
import BackBtn from 'components/BackBtn';
import Spinner from "components/Spinner";
import Toggle from "components/Toggle";
import MaterialsTable from 'components/MaterialsTable';
import JobsTable from 'components/JobsTable';
import useIsMobile from 'hooks/useIsMobile';
import useOnScan from 'util/onScan';
import { useParams, useHistory } from 'util/router';
import { commaFormat } from 'util/numbers';
import { getDefaultError } from 'util/error';
import { useGetJobQuery, useRecordJobMatlQtyPickedMutation } from "api/client";

const TABS = [
	'materials',
	'related',
];

function Job(props) {
	const history = useHistory();
	const { jobId, jobSuffix } = useParams();
	const materialPopupRef = useRef();
	const [showInvalidJobStatusAlert, setShowInvalidJobStatusAlert] = useState(false);
	const [hideInvalidJobStatusAlert, setHideInvalidJobStatusAlert] = useState(false);
	const [activeTab, setActiveTab] = useState(TABS[0]);
	const [selectedMaterial, setSelectedMaterial] = useState(null);
	const [selectMaterialLocation, setSelectMaterialLocation] = useState(null);
	const [showCompleteItems, setShowCompleteItems] = useState(false);

	const {
		data: job = {},
		isLoading: jobIsLoading,
		isFetching: jobIsFetching,
		refetch: jobRefetch,
		isError: jobIsError,
		error: jobError,
	} = useGetJobQuery({
		jobId: jobId,
		suffix: jobSuffix,
		includeCoOrderItem: true,
		includeJobMaterials: true,
		includeMaterialLocations: true,
		includeAllMaterialLocations: false,
		includeRelatedJobs: true,
	});

	const jobMaterials = useMemo(() => {
		return showCompleteItems ? job?.materials || []
			: (job?.materials || []).map(m => ({
				...m,
				locations: m?.locations.filter(l => l?.qtyToPick)
			})).filter(o => o?.locations?.length > 0);
	}, [job?.materials, showCompleteItems]);

	useEffect(() => {
		if (jobIsLoading || hideInvalidJobStatusAlert) return;
		if (job?.status !== 'R') {
			setShowInvalidJobStatusAlert(true);
		}
	}, [job?.status, jobIsLoading, hideInvalidJobStatusAlert]);

	const handleMaterialRowClick = useCallback(({material, location}) => {
		setSelectedMaterial({material, location});
		materialPopupRef.current.openPopup();
	}, [materialPopupRef]);

	const onScanCallback = useCallback((scan) => {
		if (selectedMaterial) return;

		const matchedMaterial = job?.materials?.find?.(m => m.item === scan);
		const { locations } = matchedMaterial || {};

		if (!matchedMaterial) {
			setSelectMaterialLocation(null);
		} else if (locations.length > 1) {
			setSelectMaterialLocation({
				material: matchedMaterial,
				locations: locations,
			});
		} else {
			setSelectMaterialLocation(null);
			materialPopupRef.current.openPopup();

			setSelectedMaterial({
				material: matchedMaterial,
				location: locations?.[0],
			});
		}
	}, [job?.materials, selectedMaterial]);

	useOnScan({ onScanCallback });

	// console.log(job);

	return (
		<>
			{jobIsError && (
				<JobError error={jobError} refetch={jobRefetch} />
			)}
			{showInvalidJobStatusAlert && !hideInvalidJobStatusAlert && (
				<JobInvalidStatusAlert hide={setHideInvalidJobStatusAlert}/>
			)}
			<Section
			  bg={props.bg}
			  textColor={props.textColor}
			  size={props.size}
			  bgImage={props.bgImage}
			  bgImageOpacity={props.bgImageOpacity}
			>
				<Container fluid="lg">
					<SectionHeader
					  title={!jobIsLoading ? (job?.moJobDescription || 'Job not found') : <Spinner className="text-muted"/>}
					  subtitle={!jobIsLoading && job?.description && `${job?.description} (${job?.item})`}
					  size={3}
					  spaced={true}
					  className="text-center"
					/>
					<Row>
						{/*Back btn*/}
						<Col xs={12}>
							<Flex
								align="center"
								style={{marginTop: '-1rem', marginBottom: '.5rem'}}
							>
								<BackBtn
									className="text-gray-700 text-gray-700-hvr-dark"
									onClick={() => history.goBack()}
								/>
							</Flex>
						</Col>
						{/*Summary*/}
						<Col md={12} lg={4} xl={3}>
							<JobSummaryCard
								job={job}
								qtyOrdered={job?.coOrderItem?.qtyOrdered}
								isFetching={jobIsFetching}
							/>
						</Col>
						{/*Main*/}
						<Col md={12} lg={8} xl={9}>
							<Flex justify="between" className="mb-3">
								<TabsToggle
									activeTab={activeTab}
									setActiveTab={setActiveTab}
								/>
								<Toggle
									ops={['Show all']}
									active={() => !!showCompleteItems}
									setActive={() => setShowCompleteItems(prev => !prev)}
								/>
							</Flex>
			  			<div className={classnames({
		  					'd-none': activeTab !== 'materials'
			  			})}>
			  				<MaterialsTableCard
			  					materials={jobMaterials}
			  					qtyReleased={job?.qtyReleased}
			  					isLoading={jobIsLoading}
			  					handleMaterialRowClick={handleMaterialRowClick}
			  				/>
			  			</div>
			  			<div className={classnames({
		  					'd-none': activeTab !== 'related'
			  			})}>
			  				<RelatedJobsTableCard
			  					relatedJobs={job?.relatedJobs}
			  					isLoading={jobIsLoading}
			  					setActiveTab={setActiveTab}
			  				/>
			  			</div>
						</Col>
					</Row>
				</Container>
			</Section>
			{/*Material Popup*/}
			<Popup
				ref={materialPopupRef}
				title={selectedMaterial?.material?.item || 'Material'}
				size="xl"
				afterClose={() => {
					setSelectedMaterial(null);
					jobRefetch();
				}}
			>
				{selectedMaterial && (
					<MaterialPopup
						selectedMaterial={selectedMaterial}
						closePopup={() => materialPopupRef.current.closePopup()}
					/>
				)}
			</Popup>
			{/*This pops up when user scans matl that has multiple location options*/}
			<SelectMaterialLocationPopup
				selectMaterialLocation={selectMaterialLocation}
				setSelectMaterialLocation={setSelectMaterialLocation}
				setSelectedMaterial={setSelectedMaterial}
				materialPopupRef={materialPopupRef}
			/>
		</>
	);
};

const JobError = ({error, refetch}) => (
	<Alert variant="danger">
		<Flex direction="column" align="center" className="w-100">
			<span>{getDefaultError(error)}</span>
			<span
				className="text-info-dark fw5 pointer"
				onClick={refetch}
			>
				Retry?
			</span>
		</Flex>
	</Alert>
);

const JobInvalidStatusAlert = ({hide}) => (
	<Alert variant="warning">
		<Flex direction="column" align="center" className="w-100">
			<span>This job has not been released. Are you sure you want to continue?</span>
			<span
				className="text-info-dark fw5 pointer"
				onClick={hide}
			>
				Yes, hide this
			</span>
		</Flex>
	</Alert>
);

const JobSummaryCard = ({job, qtyOrdered, isFetching}) => {
	return (
		<Card className="shadow mb-3">
			<Card.Header className="text-center">
				{isFetching ? (
					<Spinner className="text-muted w-100"/>
				) : (
					<span>{
						!job?.job ? '-'
							: `${job?.job}-${String(job?.suffix)?.padStart(4, '0')}`
					}</span>
				)}
			</Card.Header>
			<Card.Body className="py-2">
				<Flex direction="column">
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Materials</span>
						<span className="text-right">{commaFormat(job?.materials?.length, undefined, '0')}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Job Date</span>
						<span className="text-right">{job?.jobDate ? dayjs(job?.jobDate).format('MM-DD-YYYY') : '-'}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">End Date</span>
						<span className="text-right">{job?.endDate ? dayjs(job?.endDate).format('MM-DD-YYYY') : '-'}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Status</span>
						<span className="text-right">{job?.statusExpanded || '-'}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Qty Ordered</span>
						<span className="text-right">{commaFormat(qtyOrdered, undefined, '0')}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Released</span>
						<span className="text-right">{commaFormat(job?.qtyReleased, undefined, '0')}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Order</span>
						<span className="text-right">{job?.ordNum ? `${job?.ordNum}-${job?.coOrderItem?.coLine}` : '-'}</span>
					</Flex>
				</Flex>
			</Card.Body>
		</Card>
	);
};

const TabsToggle = ({activeTab, setActiveTab}) => {
	const [isMobile] = useIsMobile();

	return (
		<Toggle
			ops={TABS}
			active={activeTab}
			setActive={setActiveTab}
			className={classnames('w-fit', {
				'flex-wrap justify-content-around': isMobile
			})}
		/>
	);
};

const MaterialsTableCard = ({materials: _materials = [], qtyReleased, isLoading, handleMaterialRowClick}) => {
	const [selectedOperNum, setSelectedOperNum] = useState('all');

	const operNumOps = useMemo(() => {
		const uniqOperNums = [...new Set(_materials.map(m => m.operNum))];
		return ['all', ...uniqOperNums];
	}, [_materials]);

	const materials = useMemo(() => {
		if (selectedOperNum === 'all') {
			return _materials;
		}

		return _materials.filter(m => m.operNum === selectedOperNum);
	}, [_materials, selectedOperNum]);

	return (
		<>
			<Card className="shadow mb-3">
				<Card.Header>
					<Flex justify="between" align="center">
						<span className="mr-2">Materials</span>
						<Toggle
							ops={operNumOps}
							active={selectedOperNum}
							setActive={op => setSelectedOperNum(op)}
						/>
					</Flex>
				</Card.Header>
				<Card.Body className="p-0">
					<div>
						<MaterialsTable
							materials={materials}
							qtyReleased={qtyReleased}
							isLoading={isLoading}
							handleRowClick={handleMaterialRowClick}
							useMobileTable={true}
						/>
					</div>
				</Card.Body>
			</Card>
		</>
	);
};

const RelatedJobsTableCard = ({relatedJobs = [], isLoading, setActiveTab}) => {
	const history = useHistory();
	const [search, setSearch] = useState('');

	const handleNavigateToJob = useCallback(({ jobId, jobSuffix }) => {
		history.push(`/jobs/${jobId}/${jobSuffix}`);
		setActiveTab(TABS[0]);
	}, [history, setActiveTab]);

	return (
		<>
			<Card className="shadow mb-3">
				<Card.Header>
					<Flex justify="between" align="center">
						<span className="mr-2">Related Jobs</span>
						<div className="flex-grow-1" style={{maxWidth: '80%'}}>
							<SearchInput setSearch={setSearch} />
						</div>
					</Flex>
				</Card.Header>
				<Card.Body className="p-0">
					<div>
						<JobsTable
							jobs={relatedJobs}
							isLoading={isLoading}
							showSuffix={true}
							handleRowClick={handleNavigateToJob}
							hasLocalSearch={true}
							searchKeys={['job', 'item', 'description', 'suffix']}
							search={search}
							hasLocalSort={true}
							sort="suffix"
							sortDir="ASC"
						/>
					</div>
				</Card.Body>
			</Card>
		</>
	);
};

const SelectMaterialLocationPopup = ({selectMaterialLocation, setSelectMaterialLocation, setSelectedMaterial, materialPopupRef}) => {
	return selectMaterialLocation && (
		<Popup
			open={true}
			title={`Select location for ${selectMaterialLocation?.material?.item}`}
			afterClose={() => setSelectMaterialLocation(null)}
		>
			<Flex direction="column" align="center" className="py-3 px-2 text-center">
				<span className="text-gray-600 f-rem-0.8 fw-6">ITEM</span>
				<span className="f-rem-1.3 fw-6 text-truncate w-100">{selectMaterialLocation?.material?.item}</span>
				<span className="f-rem-0.9 mb-1.5">{selectMaterialLocation?.material?.description}</span>
				<span className="text-gray-700">This material needs to be picked from multiple locations. Which location do you want to pick from?</span>
			</Flex>
			<Flex direction="column" className="px-2.5 py-3.5 bg-gray-100 border-top border-bottom border-gray-200">
				{selectMaterialLocation?.locations?.map((location, i) => (
					<Flex
						key={location.location}
						direction="column"
						className={classnames('shadow rounded bg-white p-2', {
							'mt-2.5': i > 0,
						})}
						onClick={() => {
							setSelectedMaterial({
								material: selectMaterialLocation?.material,
								location: location
							});

							setSelectMaterialLocation(null);
							materialPopupRef.current.openPopup();
						}}
					>
						<Flex justify="between">
							<span className="f-rem-1.1 fw-5 text-truncate">{location.location}</span>
							<span className="text-right text-gray-600">Rank: {location.rank}</span>
						</Flex>
						<span className="text-gray-700">Qty avail at loc: {commaFormat(location.qtyOnHand, undefined, '-')}</span>
						<span className="text-gray-700">Qty to pick: {commaFormat(location.qtyToPick, undefined, '-')}</span>
					</Flex>
				))}
			</Flex>
		</Popup>
	);
};

const MaterialPopup = ({selectedMaterial, closePopup}) => {
	const { material, location } = selectedMaterial;
	const [materialMatch, setMaterialMatch] = useState(false);
	const [locationMatch, setLocationMatch] = useState(false);
	const notMatched = !materialMatch || !locationMatch;

	return (
		<Flex direction="column" align="center">
			<Flex direction="column" align="center" className="pt-3 pb-2 text-center w-100">
				<span className="text-gray-600 f-rem-0.8 fw-6">ITEM</span>
				<span className="f-rem-1.3 fw-6 text-truncate w-100">{material?.item}</span>
				<span className="f-rem-0.9 mb-1.5">{material?.description}</span>
			</Flex>
			{notMatched ? (
				<ScanToMatchMaterialAndLocation
					material={material}
					location={location}
					materialMatch={materialMatch}
					setMaterialMatch={setMaterialMatch}
					locationMatch={locationMatch}
					setLocationMatch={setLocationMatch}
				/>
			) : (
				<MaterialQuantityToPick
					material={material}
					location={location}
					closePopup={closePopup}
				/>
			)}
		</Flex>
	);
};

const ScanToMatchMaterialAndLocation = ({material, location, materialMatch, setMaterialMatch, locationMatch, setLocationMatch}) => {
	const [isMobile] = useIsMobile();

	const [materialId, locationId] = useMemo(() => {
		const materialId = material?.item;
		const locationId = location?.location;
		return [materialId, locationId];
	}, [material?.item, location?.location]);

	const [scanNoMatch, setScanNoMatch] = useState(false);

	const onScanCallback = useCallback((scan) => {
		setScanNoMatch(false);

		if (scan === materialId) {
			setMaterialMatch(true);
		} else if (scan === locationId) {
			setLocationMatch(true);
		} else {
			setScanNoMatch(scan);
		}
	}, [materialId, locationId, setMaterialMatch, setLocationMatch]);

	useOnScan({ onScanCallback });

	return (
		<>
			<Row className="w-100 justify-content-center border-top border-bottom border-gray-200 py-4 my-2 bg-gray-100">
				{scanNoMatch && (
					<Col xs={12} className="mb-2">
						<Alert variant="danger">
							<Flex direction="column" justify="center" className="text-center w-100">
								<span>Scan "{scanNoMatch}" didn't match material or location for this material. Please scan again.</span>
							</Flex>
						</Alert>
					</Col>
				)}
				{['material', 'location'].map((which, i) => (
					<Col
						key={which}
						xs={12}
						md={6}
						lg={5}
						className={classnames({
							'mt-3 mt-md-0': i > 0
						})}
					>
						<Flex
							direction="column"
							align="center"
							className="shadow rounded bg-white p-2 text-center"
						>
							<span className="text-uppercase f-rem-0.75 fw-6 text-gray-600 mb-0.5">{which}</span>
							<span className="f-rem-1.1 fw-5 text-truncate w-100">{
								which === 'material' ? material.item : location.location
							}</span>
							{which === 'material' ? (
								<span className="text-gray-700 text-truncate w-100">{material.description || '-'}</span>
							) : (
								<span className="f-rem-0.95 text-gray-800">Avail at Loc: <b>{commaFormat(location.qtyOnHand, undefined, '-')}</b></span>
							)}
							<Flex
								justify={!isMobile ? 'around' : 'center'}
								align="center"
								className={classnames('border-top pt-2 mt-2 w-100', {
									'text-info': which === 'material' ? !materialMatch : !locationMatch,
									'text-success': which === 'material' ? materialMatch : locationMatch,
								})}
							>
								<>
									{(which === 'material' ? !materialMatch : !locationMatch) ? (
										<Flex justify="center" align="center">
											<FontAwesomeIcon icon={faBarcodeRead} className="text-color-transition"/>
											<span className="ml-2">{
												which === 'material' ? 'Scan material at loc'
													: 'Scan loc barcode at loc'
											}</span>
										</Flex>
									) : (
										<Flex justify="center" align="center" className="w-100">
											<FontAwesomeIcon icon={faCheck}/>
											<span className="ml-2">{
												which === 'material' ? 'Material matched'
													: 'Location matched'
											}</span>
										</Flex>
									)}
									{!isMobile && (
										(which === 'material' && !materialMatch) ||
										(which === 'location' && !locationMatch)
									) ? (
											<Button
												size="sm"
												onClick={(e) => {
													try {
														e.target.setAttribute('disabled', true);
														const scanIcon = e.target.parentElement.querySelector('svg');

														setTimeout(() => {
															scanIcon.classList.toggle('text-danger');

															setTimeout(() => {
																scanIcon.classList.toggle('text-danger');

																setTimeout(() => {
																	if (which === 'material') setMaterialMatch(true);
																	else setLocationMatch(true);
																}, 150);
															}, 500);
														}, 200);
													} catch(err) {
														if (which === 'material') setMaterialMatch(true);
														else setLocationMatch(true);
													}
												}}
											>
												Simulate
											</Button>
									) : null}
								</>
							</Flex>
						</Flex>
					</Col>
				))}
			</Row>
			<Flex justify="center" className="w-100 pt-2 pb-3 px-4 text-center">
				<span className="text-muted">Scan material and location barcodes to continue</span>
			</Flex>
		</>
	);
};

const MaterialQuantityToPick = ({material, location, closePopup}) => {
	const pickToggleOps = ['partial', 'all'];
	const [pickToggleState, setPickToggleState] = useState(pickToggleOps[0]);
	const pickQtyRef = useRef();
	const [pickQtyInputErr, setPickQtyInputErr] = useState(null);

	const [recQtyPicked, recQtyPickedRes] = useRecordJobMatlQtyPickedMutation();

	const pickQtyInputCallback = useCallback(() => {
		recQtyPickedRes.reset();

		const value = pickQtyRef.current.value;
		const decimals = value.split('.')[1];
		const isLessThanFourDecimals = !decimals || decimals.length <= 4;
		const isLessThanQtyOnHand = Number(value) <= location?.qtyOnHand;

		if (!isLessThanFourDecimals) setPickQtyInputErr({message: 'There cannot be more than 4 decimal places'});
		else if (!isLessThanQtyOnHand) setPickQtyInputErr({message: `You cannot pick more than the qty on hand (${location?.qtyOnHand})`});
		// else if (!isLessThanQtyToPick) setPickQtyInputErr({message: `You cannot pick more than the qty to pick (${location?.qtyToPick})`});
		else if (value === '0') setPickQtyInputErr({message: 'Pick value must be greater than 0'});
		else setPickQtyInputErr(null);
	}, [location?.qtyOnHand, recQtyPickedRes]);

	const pickQtySubmit = useCallback(() => {
		if (recQtyPickedRes.isLoading) return;
		else recQtyPickedRes.reset();

		const qty = pickQtyRef.current.value;
		if (!qty) return setPickQtyInputErr({message: 'Pick value cannot be empty'});

		recQtyPicked({
			jobId: material?.job,
			suffix: material?.suffix,
			item: location?.item,
			qty: qty,
			location: location?.location,
		});
	}, [recQtyPicked, recQtyPickedRes, material?.job, material?.suffix, location?.location, location?.item]);

	useEffect(() => {
		if (pickToggleState === 'all') {
			pickQtyRef.current.value = location?.qtyToPick;
			setPickQtyInputErr(null);
		}
	}, [pickToggleState, location?.qtyToPick]);

	return recQtyPickedRes.isSuccess ? (
		<Alert variant="success" className="mb-0 w-100 rounded-0">
			<Flex direction="column" align="center" className="w-100 text-center">
				<span className="fw-6">Success!</span>
				<span
					className="text-info-dark fw-5 pointer"
					onClick={closePopup}
				>
					Close
				</span>
			</Flex>
		</Alert>
	) : (
		<>
			<Flex
				justify="center"
				align="center"
				className="w-100 py-2.5 border-top border-bottom border-gray-200 text-success bg-success bg-lighten-45"
			>
				<FontAwesomeIcon icon={faCheck}/>
				<span className="ml-2">Material and location verified</span>
			</Flex>
			{recQtyPickedRes.isError && (
				<Alert variant="danger" className="mb-0 w-100 rounded-0">
					<Flex direction="column" align="center" className="w-100">
						<span>{getDefaultError(recQtyPickedRes.error)}</span>
					</Flex>
				</Alert>
			)}
			<Row className="w-100 justify-content-center bg-gray-100">
				<Col xs={12} md={8} lg={5}>
					<Flex
						direction="column"
						className="shadow rounded bg-white p-2 mt-4 mb-5"
					>
						{[
							['Location', location?.location || '-'],
							['Qty on hand', commaFormat(location?.qtyOnHand, undefined, '-')],
							['Qty to pick', commaFormat(location?.qtyToPick, undefined, '-')]
						].map(([key, val]) => (
							<div key={key}>
								<Flex justify="between">
									<span className="text-gray-700">{key}</span>
									<span className="fw-5 text-truncate ml-2.5">{val}</span>
								</Flex>
								<div className="border-top border-gray-200 w-100 my-2"></div>
							</div>
						))}
						<Flex direction="column">
							<span className="mb-1 fw-5">How many are you picking?</span>
							<Flex>
								<Flex direction="column" className="w-100 mr-2.5">
									<FormField
										type="number"
										step="1"
										inputRef={pickQtyRef}
										error={pickQtyInputErr}
										className="w-100"
										onChange={pickQtyInputCallback}
										onKeyPress={e => e.charCode === 13 && pickQtySubmit()}
										disabled={pickToggleState === 'all' || null}
										style={{height: '32px'}}
									/>
								</Flex>
								<Toggle
									ops={pickToggleOps}
									active={pickToggleState}
									setActive={setPickToggleState}
									style={{maxHeight: '32px'}}
								/>
							</Flex>
							<Button
								size="sm"
								disabled={!!pickQtyInputErr || recQtyPickedRes.isLoading || null}
								className="mt-2"
								onClick={pickQtySubmit}
							>
								{!recQtyPickedRes.isLoading ? 'Confirm' : <Spinner />}
							</Button>
						</Flex>
					</Flex>
				</Col>
			</Row>
		</>
	);
};

export default Job;
