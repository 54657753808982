import React from "react";
import Meta from "components/Meta";
import PrunsSection from "components/PrunsSection";
import { requireAuth } from "util/auth";

function PrunsPage(props) {
  return (
    <>
      <Meta title="Pick List" />
      <PrunsSection
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Pruns"
      	subtitle=""
      />
    </>
  );
}

export default requireAuth(PrunsPage);
