import axios from "axios";
import { keepUnusedDataFor, apiUri } from './client/constants';

async function getUsers({search, sort, sortDir}) {
	try {
		const users = await axios.get(`${apiUri}/users`, {
			params: {
				search: search.trim(),
				sort: sort,
				sortDir: sortDir,
			}
		}).then(res => res.data);
		return { data: users };
	} catch(error) {
		return { error: error?.data?.message || error.message || 'An unknown error occured' };
	}
};

export const handlers = (builder) => ({
	getUsers: builder.query({
		queryFn: getUsers,
		keepUnusedDataFor: keepUnusedDataFor,
	}),
});

export default handlers;
