import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { useAuth } from '../../util/auth';
import { useRouter } from '../../util/router';
import './NavbarCustom.scss';

function NavbarCustom(props) {
	const auth = useAuth();
	const router = useRouter();
	const { bg, variant, expand } = props;

	return (
	  <Navbar bg={bg} variant={variant} expand={expand} collapseOnSelect className="NavbarCustom">
	    <Container>
	      <LinkContainer to="/">
	        <Navbar.Brand>
	          <img
	            className="d-inline-block align-top logo"
	            src={props.logo}
	            alt="Logo"
	          />
	        </Navbar.Brand>
	      </LinkContainer>
	      <Navbar.Toggle aria-controls="navbar-nav" className="border-0" />
	      <Navbar.Collapse id="navbar-nav" className="justify-content-end">
	        <Nav className="mr-1">
	        	{!auth.user && (
	        		<Nav.Item>
	        		  <LinkContainer to="/auth/signin">
	        		    <Nav.Link active={false}>Sign in</Nav.Link>
	        		  </LinkContainer>
	        		</Nav.Item>
	        	)}

	        	{auth.user && (
	        		<>
			          <LinkContainer to="/jobs">
			            <Nav.Link active={false}>Jobs</Nav.Link>
			          </LinkContainer>
			          <LinkContainer to="/pickLists">
			            <Nav.Link active={false}>Pick N Pack</Nav.Link>
			          </LinkContainer>
								<LinkContainer to="/pruns">
			            <Nav.Link active={false}>Pruns</Nav.Link>
			          </LinkContainer>
			          {/*<LinkContainer to="/users">
			            <Nav.Link active={false}>Users</Nav.Link>
			          </LinkContainer>*/}
			          <LinkContainer to="/auth/signout">
			          	<Nav.Link
			          		active={false}
			          		onClick={(e) => {
			          		  e.preventDefault();
			          		  auth.signout();
			          		  router.push('/auth/signin');
			          		}}
			          	>
			          		Sign out {auth?.user?.email}
			          	</Nav.Link>
			          </LinkContainer>
		          </>
	        	)}
	        </Nav>
	      </Navbar.Collapse>
	    </Container>
	  </Navbar>
	);
}

export default NavbarCustom;
