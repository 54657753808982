import { useState, useEffect, useMemo, useCallback } from 'react';

const ENTER_KEY_CODE = 13;
const MAX_INTERVAL_BETWEEN_EVENTS_IN_MS = 50;

function useOnScan({options = {}, validation, onScanCallback} = {}) {
	const [scanCode, setScanCode] = useState(null);
	const [scanError, setScanError] = useState(null);

	const [validationCallback, validationErrorMessage] = !!validation
		? Array.isArray(validation) ? validation
			: [validation]
		: [];

	const hasScanValidation = useMemo(() => typeof validationCallback === 'function', [validationCallback]);
	const customValidation = useCallback((sCode) => validationCallback(sCode), [validationCallback]);

	useEffect(() => {
		let keyCodesBuffer = [];
		let cleanBufferTimeout;

		const cleanBuffer = () => { keyCodesBuffer = [] };

		function listener(e) {
			const { key, keyCode } = e;
			clearTimeout(cleanBufferTimeout);

			if (keyCode === ENTER_KEY_CODE) {
				const sCode = keyCodesBuffer.join('');
				cleanBuffer();

				if (
					options?.minLength &&
					sCode.length < options?.minLength
				) {
					setScanCode(null);
					return setScanError({ code: sCode, message: `Code ${sCode} was less than min required length (${options.minLength})` });
				}

				if (hasScanValidation) {
					if (customValidation(sCode)) {
						setScanCode(sCode);
						setScanError(null);

						if (typeof onScanCallback === 'function') {
							onScanCallback(sCode);
						}
					} else {
						setScanCode(null);
						setScanError({ code: sCode, message: `Scan '${sCode}' ${validationErrorMessage || 'failed custom validation'}` });
					}
				} else {
					setScanCode(sCode);
					setScanError(null);

					if (typeof onScanCallback === 'function') {
						onScanCallback(sCode);
					}
				}
			} else {
				keyCodesBuffer.push(key);
				cleanBufferTimeout = setTimeout(cleanBuffer, MAX_INTERVAL_BETWEEN_EVENTS_IN_MS);
			}
		};

		document.addEventListener('keypress', listener);

		return () => document.removeEventListener('keypress', listener);
	}, [customValidation, validationErrorMessage, hasScanValidation, onScanCallback, options?.minLength]);

	return [scanCode, scanError];
};

export default useOnScan;
