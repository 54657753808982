import React, { useState, useRef } from "react";
import axios from "axios";
import dayjs from 'dayjs';
import relativeTime from'dayjs/plugin/relativeTime';
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Popup from "components/Popup";
import Flex from "components/Flex";
import SearchInput from "components/SearchInput";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import SortIcon from "components/SortIcon";
import Spinner from "components/Spinner";
import { useGetUsersQuery } from 'api/client';
import { apiUri } from 'api/client/constants';
dayjs.extend(relativeTime);

function UsersTable(props) {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('id');
  const [sortDir, setSortDir] = useState('ASC');
  const [popupOpen, setPopupOpen] = useState(false);
  const [activeUser, setActiveUser] = useState(false);

  const {
  	data: users = [],
  	isLoading: usersIsLoading,
  } = useGetUsersQuery({search, sort, sortDir});

  function handleSortToggle(e) {
  	const col = e.currentTarget.getAttribute('colname');
  	const newState = sort !== col ? 'ASC'
  		: sortDir === 'DESC' ? 'ASC'
  			: 'DESC';

  	setSort(col);
  	setSortDir(newState);
  }

  return (
  	<>
	  	<Section
	  	  bg={props.bg}
	  	  textColor={props.textColor}
	  	  size={props.size}
	  	  bgImage={props.bgImage}
	  	  bgImageOpacity={props.bgImageOpacity}
	  	>
	  	  <Container>
	  	  	<SectionHeader
	  	  	  title={props.title}
	  	  	  subtitle={props.subtitle}
	  	  	  size={1}
	  	  	  spaced={true}
	  	  	  className="text-center"
	  	  	/>
	  	  	<Card className="shadow">
	  	  		<Card.Header>
	  	  			<SearchInput setSearch={setSearch} />
	  	  		</Card.Header>
	  	  		<Card.Body className="p-0">
	  	  			{usersIsLoading ? (
	  	  				<Spinner className="py-2 text-muted w-100"/>
	  	  			) : users.length > 0 ? (
	  	  				<div>
			  	  			<Table
			  	  				hover responsive
			  	  				className="table-stripe"
			  	  			>
			  	  				<TableHeader
			  	  					sort={sort}
			  	  					sortDir={sortDir}
			  	  					handleSortToggle={handleSortToggle}
			  	  				/>
							      <tbody>
							      	{users.map(user => (
							      		<UserRow
							      			key={user.id}
							      			user={user}
							      			setActiveUser={setActiveUser}
							      			setPopupOpen={setPopupOpen}
							      		/>
							      	))}
							      </tbody>
							    </Table>
							  </div>
	  	  			) : (
	  	  				<tr>
	  	  					<td colSpan="7" className="text-center">
	  	  						No users found
	  	  					</td>
	  	  				</tr>
	  	  			)}
	  	  		</Card.Body>
	  	  	</Card>
	  	  </Container>
	  	</Section>
	  	<SetPasswordPopup
	  		popupOpen={popupOpen}
	  		setPopupOpen={setPopupOpen}
	  		user={activeUser}
	  		setActiveUser={setActiveUser}
	  	/>
	  </>
  );
};

export const SetPasswordPopup = ({user, setActiveUser, popupOpen, setPopupOpen}) => {
	const popupRef = useRef();
	const [isDisabled, setIsDisabled] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [newPassword, setNewPassword] = useState('');

	function handleSetCustomPassword(newPassword) {
		setError(false);
		setSuccess(false);
		setIsDisabled(true);

		axios.get(`${apiUri}/users/resetPassword`, {
			params: {
				userId: user?.id,
				email: user?.email,
				password: newPassword,
			}
		}).then(res => {
			setSuccess(true);
		}).catch(err => {
			if (err?.response?.data) {
				setError(err.response.data.message);
			} else {
				setError(err.message || 'An unknown error occured');
			}

			console.error(err);
		})
		.then(() => {
			setIsDisabled(false);
		});
	}

	return popupOpen && (
		<Popup
			ref={popupRef}
			open={popupOpen}
			title={`Set password for ${user?.email}`}
			size="lg"
			containerClass="bg-light"
			backBtnClass="bg-white"
			afterClose={() => {
				setActiveUser(null);
				setNewPassword('');
				setError(null);
				setSuccess(null);
				setPopupOpen(false);
			}}
		>
			<Flex direction="column" className="p-3" wrap="nowrap">
	      <Button
	      	variant="primary"
	      	className="ml-2 text-nowrap"
	      	onClick={() => {
	      		if (!window.confirm('Please confirm') || isDisabled) return;
	      		return handleSetCustomPassword('12345678');
	      	}}
	      >
          Set to 12345678
        </Button>
				<span className="text-center text-muted border-top mt-4 pt-3 pb-2">Or set custom password</span>
				<Flex>
					<Form.Control
		        placeholder="Type new password"
		        aria-label="Search"
		        value={newPassword}
		        onChange={e => {
		        	setError(false);
		        	setNewPassword(e.target.value);
		        }}
		        onKeyPress={e => {
		        	if (e.which === 13) {
			        	if (!newPassword.trim()) return setError(`Password can't be empty`);
			        	if (!window.confirm('Please confirm') || isDisabled) return;

			        	return handleSetCustomPassword(newPassword.trim());
		        	}
		        }}
		      />
		      <Button
		      	variant="outline-secondary"
		      	disabled={isDisabled}
		      	className="ml-2 text-nowrap"
		      	onClick={() => {
		      		if (!newPassword.trim()) return setError(`Password can't be empty`);
		      		if (!window.confirm('Please confirm') || isDisabled) return;

		      		return handleSetCustomPassword(newPassword.trim());
		      	}}
		      >
	          Set PW
	        </Button>
				</Flex>

				{error && (
					<Alert variant="danger" className="mt-3">
						{error}
					</Alert>
				)}

				{success && (
					<Alert variant="success" className="mt-3">
						Password reset
					</Alert>
				)}
			</Flex>
		</Popup>
	);
};

export const TableHeader = ({sort, sortDir, handleSortToggle}) => {
	return (
		<thead>
		  <tr>
				<th scope="col" colname="id" className="hasSort" onClick={handleSortToggle}>
					<span className="mr-2">ID</span>
					<SortIcon direction={sort === 'id' ? sortDir : 'hide'}/>
				</th>
				<th scope="col" colname="firstName" className="hasSort" onClick={handleSortToggle}>
					<span className="mr-2">Name</span>
					<SortIcon direction={sort === 'firstName' ? sortDir : 'hide'}/>
				</th>
				<th scope="col" colname="email" className="hasSort" onClick={handleSortToggle}>
					<span className="mr-2">Email</span>
					<SortIcon direction={sort === 'email' ? sortDir : 'hide'}/>
				</th>
				<th scope="col" colname="emailConfirmed" className="hasSort" onClick={handleSortToggle}>
					<span className="mr-2">Confirmed</span>
					<SortIcon direction={sort === 'emailConfirmed' ? sortDir : 'hide'}/>
				</th>
				<th scope="col" colname="role" className="hasSort" onClick={handleSortToggle}>
					<span className="mr-2">Role</span>
					<SortIcon direction={sort === 'role' ? sortDir : 'hide'}/>
				</th>
				<th scope="col" colname="lastAccessed" className="hasSort" onClick={handleSortToggle}>
					<span className="mr-2">Last Online</span>
					<SortIcon direction={sort === 'lastAccessed' ? sortDir : 'hide'}/>
				</th>
		    <th>Password</th>
		  </tr>
		</thead>
	);
};

export const UserRow = ({user, setActiveUser, setPopupOpen}) => {
	return (
		<tr
			onClick={() => {
				setActiveUser(user);
				setPopupOpen(true);
			}}
		>
		  <td>{user?.id}</td>
		  <td>{`${user?.firstName} ${user?.lastName}`.trim()}</td>
		  <td>{user?.email}</td>
		  <td>{user?.emailConfirmed ? 'True' : 'False'}</td>
		  <td>{user?.role}</td>
		  <td className="text-capitalize">{user?.lastAccessed ? dayjs(user.lastAccessed).fromNow() : "Never"}</td>
		  <td>
		  	<Button
		  		variant="primary"
		  		size="sm"
		  		onClick={() => {
		  			setActiveUser(user);
		  			setPopupOpen(true);
		  		}}
		  		className="w-100"
		  	>
		  		EDIT
		  	</Button>
		  </td>
		</tr>
	);
};

export default UsersTable;
