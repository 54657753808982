import queryConstructor from '../../util/queryConstructor';
import { keepUnusedDataFor } from './constants';

function getJobs({status, search, sort, sortDir}) {
	const queryParams = queryConstructor({status, search, sort, sortDir});
	return `fetchJobs?${queryParams}`;
};

function getJob({
	jobId,
	suffix,
	includeCoOrderItem,
	includeJobMaterials,
	includeMaterialLocations,
	includeAllMaterialLocations,
	includeRelatedJobs
}) {
	const queryParams = queryConstructor({
		suffix,
		includeCoOrderItem,
		includeJobMaterials,
		includeMaterialLocations,
		includeAllMaterialLocations,
		includeRelatedJobs,
	});

	return `fetchJob/${jobId}?${queryParams}`;
};

// Mutations
function recordJobMatlQtyPicked({jobId, suffix, item, qty, location} = {}) {
	const queryParams = queryConstructor({jobId, suffix, item, qty, location});
	return `recordQtyPicked?${queryParams}`;
}

const handlers = (builder) => ({
	getJobs: builder.query({
		query: getJobs,
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getJob: builder.query({
		query: getJob,
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	// Mutations
	recordJobMatlQtyPicked: builder.mutation({
		query: recordJobMatlQtyPicked,
	}),
});

export default handlers;
