import React, { useMemo } from "react";
import dayjs from "dayjs";
import StripeTable from "components/StripeTable";

const DEFAULT_SORT_COL = 'pickDate';
const DEFAULT_SORT_DIRECTION = 'DESC';

const HEADERS = [
	{
		key: 'id',
		name: 'ID',
	}, {
		key: 'UfProjectName',
		name: 'Name',
	}, {
		key: 'statusExpanded',
		name: 'Status',
	}, {
		key: 'coNum',
		name: 'CO Num',
	}, {
		key: 'date',
		name: 'pickDate',
	},
];

function PickListsTable(props) {
	const {
		pickLists = [],
		...rest
	} = props;

	const data = useMemo(() => {
		return pickLists.map(pickList => ({
			id: pickList.id,
			orig: pickList,
			values: [
				// ID
				{ value: pickList.id || '-', className: 'text-nowrap' },
				// Project name
				{ value: pickList?.UfProjectName || '-', className: 'text-nowrap' },
				// Status
				{ value: pickList?.statusExpanded || '-', className: 'text-nowrap' },
				// CO Num
				{ value: pickList?.coNum || '-', className: 'text-nowrap' },
				// Pick Date
				{ value: pickList?.pickDate ? dayjs(pickList?.pickDate).format('MM-DD-YYYY') : '-', className: 'text-nowrap' },
			],
		}))
	}, [pickLists]);

	return (
		<StripeTable
			headers={HEADERS}
			defaultSortCol={DEFAULT_SORT_COL}
			defaultSortDir={DEFAULT_SORT_DIRECTION}
			data={data}
			noneFoundText="No pick lists found"
			{...rest}
		/>
	);
};

export default PickListsTable;
