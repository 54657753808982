import React from "react";
import Meta from "components/Meta";
import Prun from "components/Prun";
import { requireAuth } from "util/auth";

function PrunPage(props) {
  return (
    <>
      <Meta title="Prun" />
      <Prun
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Prun"
      	subtitle=""
      />
    </>
  );
}

export default requireAuth(PrunPage);
