import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import classnames from 'classnames';
import dayjs from 'dayjs';
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faBarcodeRead } from '@fortawesome/pro-duotone-svg-icons';
import SearchInput from "components/SearchInput";
import FormField from "components/FormField";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Popup from "components/Popup";
import Flex from 'components/Flex';
import BackBtn from 'components/BackBtn';
import Spinner from "components/Spinner";
import Toggle from "components/Toggle";
import StripeTable from "components/StripeTable";
import useIsMobile from 'hooks/useIsMobile';
import useOnScan from 'util/onScan';
import { useParams, useHistory } from 'util/router';
import { commaFormat } from 'util/numbers';
import { getDefaultError } from 'util/error';
import { useGetPickListQuery, useRecordPickListQtyPickedMutation } from "api/client";

const TABS = [
	'items',
];

function PickList(props) {
	const history = useHistory();
	const { pickListId } = useParams();
	const itemPopupRef = useRef();
	const [activeTab, setActiveTab] = useState(TABS[0]);
	const [selectedItem, setSelectedItem] = useState(null);
	const [showCompleteItems, setShowCompleteItems] = useState(false);

	const {
		data: pickList = {},
		isLoading: pickListIsLoading,
		isFetching: pickListIsFetching,
		refetch: pickListRefetch,
		isError: pickListIsError,
		error: pickListError,
	} = useGetPickListQuery({ pickListId });

	const pickListRefs = useMemo(() => {
		return showCompleteItems ? pickList?.refs || []
			: (pickList?.refs || []).filter(o => o?.qtyToPick - o?.qtyPicked > 0);
	}, [pickList?.refs, showCompleteItems]);

	const handleItemRowClick = useCallback(item => {
		setSelectedItem(item);
		itemPopupRef.current.openPopup();
	}, [itemPopupRef]);

	const onScanCallback = useCallback((scan) => {
		if (selectedItem) return;

		const matchedItem = pickList?.refs?.find?.(i => i.item === scan);

		if (!matchedItem) {
			setSelectedItem(null);
		} else {
			setSelectedItem(matchedItem);
			itemPopupRef.current.openPopup();
		}
	}, [pickList?.refs, selectedItem]);

	useOnScan({ onScanCallback });

	// console.log(pickList);

	return (
		<>
			{pickListIsError && <PickListError error={pickListError} refetch={pickListRefetch} />}
			<Section
			  bg={props.bg}
			  textColor={props.textColor}
			  size={props.size}
			  bgImage={props.bgImage}
			  bgImageOpacity={props.bgImageOpacity}
			>
				<Container fluid="lg">
					<SectionHeader
					  title={!pickListIsLoading ? (pickList?.UfProjectName || 'Pick List not found') : <Spinner className="text-muted"/>}
					  subtitle={!pickListIsLoading && pickList?.coNum}
					  size={3}
					  spaced={true}
					  className="text-center"
					/>
					<Row>
						{/*Back btn*/}
						<Col xs={12}>
							<Flex
								align="center"
								style={{marginTop: '-1rem', marginBottom: '.5rem'}}
							>
								<BackBtn
									className="text-gray-700 text-gray-700-hvr-dark"
									onClick={() => history.goBack()}
								/>
							</Flex>
						</Col>
						{/*Summary*/}
						<Col md={12} lg={4} xl={3}>
							<PickListSummaryCard
								pickList={pickList}
								isFetching={pickListIsFetching}
							/>
						</Col>
						{/*Main*/}
						<Col md={12} lg={8} xl={9}>
							<Flex justify="between" className="mb-3">
								<TabsToggle
									activeTab={activeTab}
									setActiveTab={setActiveTab}
								/>
								<Toggle
									ops={['Show all']}
									active={() => !!showCompleteItems}
									setActive={() => setShowCompleteItems(prev => !prev)}
								/>
							</Flex>
			  			<div className={classnames({
		  					'd-none': activeTab !== 'items'
			  			})}>
			  				<ItemsTableCard
			  					items={pickListRefs}
			  					isLoading={pickListIsLoading}
			  					handleItemRowClick={handleItemRowClick}
			  				/>
			  			</div>
						</Col>
					</Row>
				</Container>
			</Section>
			{/*Item popup*/}
			<Popup
				ref={itemPopupRef}
				title={selectedItem?.item || 'Item'}
				size="xl"
				afterClose={() => {
					setSelectedItem(null);
					pickListRefetch();
				}}
			>
				{selectedItem && (
					<ItemPopup
						selectedItem={selectedItem}
						closePopup={() => itemPopupRef.current.closePopup()}
					/>
				)}
			</Popup>
		</>
	);
};

const PickListError = ({error, refetch}) => (
	<Alert variant="danger">
		<Flex direction="column" align="center" className="w-100">
			<span>{getDefaultError(error)}</span>
			<span
				className="text-info-dark fw5 pointer"
				onClick={refetch}
			>
				Retry?
			</span>
		</Flex>
	</Alert>
);

const PickListSummaryCard = ({pickList, isFetching}) => {
	return (
		<Card className="shadow mb-3">
			<Card.Header className="text-center">
				{isFetching ? (
					<Spinner className="text-muted w-100"/>
				) : (
					<span>{pickList?.id || '-'}</span>
				)}
			</Card.Header>
			<Card.Body className="py-2">
				<Flex direction="column">
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Items</span>
						<div className="text-right">
							<span>{commaFormat(pickList?.refs?.filter(o => o?.qtyToPick - o?.qtyPicked > 0)?.length, undefined, '-')}</span>
							<span className="text-gray-400 px-1">/</span>
							<span className="text-gray-600">{commaFormat(pickList?.refs?.length, undefined, '-')}</span>
						</div>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Pack Loc</span>
						<span className="text-right">{pickList?.packLoc || '-'}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Status</span>
						<span className="text-right">{pickList?.statusExpanded || '-'}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Pick Date</span>
						<span className="text-right">{pickList?.pickDate ? dayjs(pickList?.pickDate).format('MM-DD-YYYY') : '-'}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Co Num</span>
						<span className="text-right">{pickList?.coNum || '-'}</span>
					</Flex>
					<Flex justify="between" className="py-1">
						<span className="text-gray-700">Cust Num</span>
						<span className="text-right">{pickList?.custNum || '-'}</span>
					</Flex>
				</Flex>
			</Card.Body>
		</Card>
	);
};

const TabsToggle = ({activeTab, setActiveTab}) => {
	const [isMobile] = useIsMobile();

	return (
		<Toggle
			ops={TABS}
			active={activeTab}
			setActive={setActiveTab}
			className={classnames('w-fit', {
				'flex-wrap justify-content-around': isMobile
			})}
		/>
	);
};

const ITEM_TABLE_HEADERS = [
	{
		key: 'sequence',
		name: 'Seq'
	}, {
		key: 'item',
		name: 'Item'
	}, {
		key: 'description',
		name: 'Desc'
	}, {
		key: 'unitOfMeasure',
		name: 'U/M'
	}, {
		key: 'qtyToPick_qtyPicked',
		name: 'Req/Issued'
	}, {
		key: 'qtyLeftToPick',
		name: 'To Pick'
	}, {
		key: 'qtyAvailLoc',
		name: 'Qty Avail Loc'
	}, {
		key: 'loc',
		name: 'Location'
	},
];

const ItemsTableCard = ({items = [], isLoading, handleItemRowClick}) => {
	const [search, setSearch] = useState('');

	const data = useMemo(() => {
		return items.map((item, i) => ({
			key: `${item.item}-${i}`, //there are sometimes duplicate items
			id: item?.item,
			orig: item,
			values: [
				{ value: item?.sequence || '-' },
				{ value: item?.item || '-', className: 'text-nowrap' },
				{ value: item?.description || '-' },
				{ value: item?.unitOfMeasure || '-' },
				{ value: `${commaFormat(item?.qtyToPick, undefined, '-')} / ${commaFormat(item?.qtyPicked, undefined, '-')}` },
				{ value: commaFormat(item?.qtyToPick - item?.qtyPicked, undefined, '-') },
				{ value: commaFormat(item?.location?.qtyOnHand, undefined, '-') },
				{ value: item?.location?.location || '-', className: 'text-nowrap' },
			],
			callbackValue: item,
		}));
	}, [items]);

	return (
		<>
			<Card className="shadow mb-3">
				<Card.Header>
					<Flex justify="between" align="center">
						<span className="mr-2">Items</span>
						<div className="flex-grow-1" style={{maxWidth: '80%'}}>
							<SearchInput setSearch={setSearch} />
						</div>
					</Flex>
				</Card.Header>
				<Card.Body className="p-0">
					<div>
						<StripeTable
							headers={ITEM_TABLE_HEADERS}
							hasLocalSort={true}
							defaultSortCol="sequence"
							defaultSortDir="ASC"
							data={data}
							hasLocalSearch={true}
							search={search}
							searchKeys={['item', 'description']}
							isLoading={isLoading}
							handleRowClick={handleItemRowClick}
							useMobileTable={true}
						/>
					</div>
				</Card.Body>
			</Card>
		</>
	);
};

const ItemPopup = ({selectedItem, closePopup}) => {
	const { location } = selectedItem;
	const [itemMatch, setItemMatch] = useState(false);
	const [locationMatch, setLocationMatch] = useState(false);
	const notMatched = !itemMatch || !locationMatch;

	return (
		<Flex direction="column" align="center">
			<Flex direction="column" align="center" className="pt-3 pb-2 text-center w-100">
				<span className="text-gray-600 f-rem-0.8 fw-6">ITEM</span>
				<span className="f-rem-1.3 fw-6 text-truncate w-100">{selectedItem?.item}</span>
				<span className="f-rem-0.9 mb-1.5">{selectedItem?.description}</span>
			</Flex>
			{notMatched ? (
				<ScanToMatchItemAndLocation
					item={selectedItem}
					location={location}
					itemMatch={itemMatch}
					setItemMatch={setItemMatch}
					locationMatch={locationMatch}
					setLocationMatch={setLocationMatch}
				/>
			) : (
				<ItemQuantityToPick
					item={selectedItem}
					location={location}
					closePopup={closePopup}
				/>
			)}
		</Flex>
	);
};

const ScanToMatchItemAndLocation = ({item, location, itemMatch, setItemMatch, locationMatch, setLocationMatch}) => {
	const [isMobile] = useIsMobile();

	const [itemId, locationId] = useMemo(() => {
		const itemId = item?.item;
		const locationId = location?.location;
		return [itemId, locationId];
	}, [item?.item, location?.location]);

	const [scanNoMatch, setScanNoMatch] = useState(false);

	const onScanCallback = useCallback((scan) => {
		setScanNoMatch(false);

		if (scan === itemId) {
			setItemMatch(true);
		} else if (scan === locationId) {
			setLocationMatch(true);
		} else {
			setScanNoMatch(scan);
		}
	}, [itemId, locationId, setItemMatch, setLocationMatch]);

	useOnScan({ onScanCallback });

	return (
		<>
			<Row className="w-100 justify-content-center border-top border-bottom border-gray-200 py-4 my-2 bg-gray-100">
				{scanNoMatch && (
					<Col xs={12} className="mb-2">
						<Alert variant="danger">
							<Flex direction="column" justify="center" className="text-center w-100">
								<span>Scan "{scanNoMatch}" didn't match item or location for this item. Please scan again.</span>
							</Flex>
						</Alert>
					</Col>
				)}
				{['item', 'location'].map((which, i) => (
					<Col
						key={which}
						xs={12}
						md={6}
						lg={5}
						className={classnames({
							'mt-3 mt-md-0': i > 0
						})}
					>
						<Flex
							direction="column"
							align="center"
							className="shadow rounded bg-white p-2 text-center"
						>
							<span className="text-uppercase f-rem-0.75 fw-6 text-gray-600 mb-0.5">{which}</span>
							<span className="f-rem-1.1 fw-5 text-truncate w-100">{
								which === 'item' ? item.item : location.location
							}</span>
							{which === 'item' ? (
								<span className="text-gray-700 text-truncate w-100">{item.description || '-'}</span>
							) : (
								<span className="f-rem-0.95 text-gray-800">Avail at Loc: <b>{commaFormat(location.qtyOnHand, undefined, '-')}</b></span>
							)}
							<Flex
								justify={!isMobile ? 'around' : 'center'}
								align="center"
								className={classnames('border-top pt-2 mt-2 w-100', {
									'text-info': which === 'item' ? !itemMatch : !locationMatch,
									'text-success': which === 'item' ? itemMatch : locationMatch,
								})}
							>
								<>
									{(which === 'item' ? !itemMatch : !locationMatch) ? (
										<Flex justify="center" align="center">
											<FontAwesomeIcon icon={faBarcodeRead} className="text-color-transition"/>
											<span className="ml-2">{
												which === 'item' ? 'Scan item at loc'
													: 'Scan loc barcode at loc'
											}</span>
										</Flex>
									) : (
										<Flex justify="center" align="center" className="w-100">
											<FontAwesomeIcon icon={faCheck}/>
											<span className="ml-2">{
												which === 'item' ? 'Item matched'
													: 'Location matched'
											}</span>
										</Flex>
									)}
									{!isMobile && (
										(which === 'item' && !itemMatch) ||
										(which === 'location' && !locationMatch)
									) ? (
											<Button
												size="sm"
												onClick={(e) => {
													try {
														e.target.setAttribute('disabled', true);
														const scanIcon = e.target.parentElement.querySelector('svg');

														setTimeout(() => {
															scanIcon.classList.toggle('text-danger');

															setTimeout(() => {
																scanIcon.classList.toggle('text-danger');

																setTimeout(() => {
																	if (which === 'item') setItemMatch(true);
																	else setLocationMatch(true);
																}, 150);
															}, 500);
														}, 200);
													} catch(err) {
														if (which === 'item') setItemMatch(true);
														else setLocationMatch(true);
													}
												}}
											>
												Simulate
											</Button>
									) : null}
								</>
							</Flex>
						</Flex>
					</Col>
				))}
			</Row>
			<Flex justify="center" className="w-100 pt-2 pb-3 px-4 text-center">
				<span className="text-muted">Scan item and location barcodes to continue</span>
			</Flex>
		</>
	);
};

const ItemQuantityToPick = ({item, location, closePopup}) => {
	const pickToggleOps = ['partial', 'all'];
	const [pickToggleState, setPickToggleState] = useState(pickToggleOps[0]);
	const pickQtyRef = useRef();
	const [pickQtyInputErr, setPickQtyInputErr] = useState(null);
	const qtyLeftToPick = useMemo(() => item?.qtyToPick - item?.qtyPicked, [item?.qtyPicked, item?.qtyToPick]);

	const [recQtyPicked, recQtyPickedRes] = useRecordPickListQtyPickedMutation();

	const pickQtyInputCallback = useCallback(() => {
		const value = pickQtyRef.current.value;
		const decimals = value.split('.')[1];
		const isLessThanFourDecimals = !decimals || decimals.length <= 4;
		const isLessThanQtyOnHand = Number(value) <= location?.qtyOnHand;
		const isLessThanQtyToPick = Number(value) <= qtyLeftToPick;

		if (!isLessThanFourDecimals) setPickQtyInputErr({message: 'There cannot be more than 4 decimal places'});
		else if (!isLessThanQtyOnHand) setPickQtyInputErr({message: `You cannot pick more than the qty on hand (${location?.qtyOnHand})`});
		else if (!isLessThanQtyToPick) setPickQtyInputErr({message: `You cannot pick more than the qty to pick (${qtyLeftToPick})`});
		else if (value === '0') setPickQtyInputErr({message: 'Pick value must be greater than 0'});
		else setPickQtyInputErr(null);
	}, [qtyLeftToPick, location?.qtyOnHand]);

	const pickQtySubmit = useCallback(() => {
		if (recQtyPickedRes.isLoading) return;
		else recQtyPickedRes.reset();

		const qty = pickQtyRef.current.value;
		if (!qty) return setPickQtyInputErr({message: 'Pick value cannot be empty'});

		recQtyPicked({
			pickListId: item?.id,
			sequence: item?.sequence,
			item: item?.item,
			refNum: item?.refNum,
			refLineSuf: item?.refLineSuf,
			qty: qty,
		});
	}, [recQtyPicked, recQtyPickedRes, item?.id, item?.sequence, item?.item, item?.refNum, item?.refLineSuf]);

	useEffect(() => {
		if (pickToggleState === 'all') {
			pickQtyRef.current.value = qtyLeftToPick;
			setPickQtyInputErr(null);
		}
	}, [pickToggleState, qtyLeftToPick]);

	return recQtyPickedRes.isSuccess ? (
		<Alert variant="success" className="mb-0 w-100 rounded-0">
			<Flex direction="column" align="center" className="w-100 text-center">
				<span className="fw-6">Success!</span>
				<span
					className="text-info-dark fw-5 pointer"
					onClick={closePopup}
				>
					Close
				</span>
			</Flex>
		</Alert>
	) : (
		<>
			<Flex
				justify="center"
				align="center"
				className="w-100 py-2.5 border-top border-bottom border-gray-200 text-success bg-success bg-lighten-45"
			>
				<FontAwesomeIcon icon={faCheck}/>
				<span className="ml-2">Item and location verified</span>
			</Flex>
			<Row className="w-100 justify-content-center bg-gray-100">
				<Col xs={12} md={8} lg={5}>
					<Flex
						direction="column"
						className="shadow rounded bg-white p-2 mt-4 mb-5"
					>
						{[
							['Location', location?.location || '-'],
							['Qty on hand', commaFormat(location?.qtyOnHand, undefined, '-')],
							['Qty to pick', commaFormat(qtyLeftToPick, undefined, '-')]
						].map(([key, val]) => (
							<div key={key}>
								<Flex justify="between">
									<span className="text-gray-700">{key}</span>
									<span className="fw-5 text-truncate ml-2.5">{val}</span>
								</Flex>
								<div className="border-top border-gray-200 w-100 my-2"></div>
							</div>
						))}
						<Flex direction="column">
							<span className="mb-1 fw-5">How many are you picking?</span>
							<Flex>
								<Flex direction="column" className="w-100 mr-2.5">
									<FormField
										type="number"
										step="1"
										inputRef={pickQtyRef}
										error={pickQtyInputErr}
										className="w-100"
										onChange={pickQtyInputCallback}
										onKeyPress={e => e.keyCode === 13 && pickQtySubmit()}
										disabled={pickToggleState === 'all' || null}
										style={{height: '32px'}}
									/>
								</Flex>
								<Toggle
									ops={pickToggleOps}
									active={pickToggleState}
									setActive={setPickToggleState}
									style={{maxHeight: '32px'}}
								/>
							</Flex>
							<Button
								size="sm"
								disabled={!!pickQtyInputErr || recQtyPickedRes.isLoading || null}
								className="mt-2"
								onClick={pickQtySubmit}
							>
								{!recQtyPickedRes.isLoading ? 'Confirm' : <Spinner />}
							</Button>
						</Flex>
					</Flex>
				</Col>
			</Row>
		</>
	);
};

export default PickList;
