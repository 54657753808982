import queryConstructor from '../../util/queryConstructor';
// import { keepUnusedDataFor } from './constants';

function getPruns({prunIds, status, search, page, sort, sortDir, includeTransformedForEton} = {}) {
	const queryParams = queryConstructor({prunIds, status, search, sort, page, sortDir, includeTransformedForEton});
	return `fetchPruns?${queryParams}`;
};

// Mutations
function postPrun({prunId} = {}) {
	return {
		url: `postPruns`,
		method: 'POST',
		body: { prunIds: [prunId] },
	}
}

const handlers = (builder) => ({
	getPruns: builder.query({
		query: getPruns,
		keepUnusedDataFor: 10,
	}),

	// Mutations
	postPrun: builder.mutation({
		query: postPrun,
	}),
});

export default handlers;
